// const environment = 'development';
const environment = "production";

const url = {
  development: "http://localhost:10000/",
  production: "https://backoffice.saxdorturkey.com/",
};

export const AppConfig = {
  ApiUrl: url[environment],
  AssetUrl: url[environment],
  Environment: environment,
  GoogleApiKey: "AIzaSyAM1cLP__B068fQA7NWJdFOYzqvsKr_K0E",
};

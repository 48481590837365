import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AuthActions from "../../redux/actions/auth";
import Axios from "axios";
import Layout from "../../components/common/Layout";
import { VDLoader } from "../../components/common/VDLoader";
import ReactCodeInput from "react-verification-code-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import qs from "query-string";

class SmsVerification extends React.Component {
  state = {
    code: null,
    loading: false,
    disabled: false,
  };

  onActivate = () => {
    if (this.state.code == null) {
      alert("Please enter 4 digit code.");
      return;
    }

    const parsedQuery = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    this.setState({ loading: true });
    this.props.validateCode(
      this.props.authStore.phone,
      this.state.code,
      parsedQuery.callback
        ? () => this.props.history.push(`${parsedQuery.callback}`)
        : undefined,
    );
  };

  onResend = () => {
    this.props.validateNumber(this.props.authStore.phone, true);
    this.setState({ disabled: true });
    alert(`Code sent to ${this.props.authStore.phone}`);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.authStore.validationMessage) {
      alert(nextProps.authStore.validationMessage);
      nextProps.clearMessage();
      this.setState({ loading: false });
    }

    if (nextProps.authStore.member != null && !nextProps.authStore.resend) {
      if (nextProps.authStore.member.email != null) {
        const token = localStorage.getItem("token");
        if (token != null) {
          Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          this.props.history.push("/");
        }
      } else {
        this.props.history.push("/create-profile");
      }
    }
  }

  render() {
    const { loading, disabled } = this.state;
    if (loading) return <VDLoader />;
    return (
      <Layout nothome>
        <section id="sms-verification">
          <div className="sms-verification-box col-md-5 col-sm-12">
            <h1>SUCCESS!</h1>
            <p className="sms-verification-text">
              Check your phone we have sent you a confirmation code to verify
              your account.
            </p>
            <ReactCodeInput
              fields={4}
              className="sms-input"
              fieldHeight={85}
              fieldWidth={65}
              onChange={(code) => this.setState({ code })}
            />
            .
            <div className="row verification-actions-box">
              <div className="col-md-5 col-sm-12">
                {/* <a href="/login" className="back">
                  <FontAwesomeIcon icon={faLongArrowAltLeft} />Back
                </a> */}
                {!disabled && (
                  <a className="resend" onClick={this.onResend}>
                    <FontAwesomeIcon icon={faSync} />
                    Resend Code
                  </a>
                )}
              </div>
              <div className="col-md-7 col-sm-12">
                <a onClick={this.onActivate}>
                  <img
                    src={require("../../assets/images/verify.png")}
                    alt=""
                    className="verify-button"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

const mapState = (state) => {
  return {
    authStore: state.authReducer,
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(AuthActions, dispatch);
};

export default connect(mapState, mapDispatch)(SmsVerification);

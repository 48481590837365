import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/common/Layout";

class PaymentFail extends React.Component {
  render() {
    const message = this.props.location.state.message;
    return (
      <Layout nothome>
        <div className="container">
          <div className="row" style={{ marginTop: 50, marginBottom: 50 }}>
            <div className="col-md-6 offset-md-3">
              <h1>PAYMENT DECLINED</h1>
              <p style={{ color: "grey" }}>
                <strong>
                  Oops… Something went{"\n"}wrong. Please try again or{"\n"}
                  select a different payment option.
                </strong>
              </p>
              {message && <p>{message}</p>}
              <div className="col-md-6 col-sm-12 offset-md-3">
                <div className="row">
                  <Link to="/payment" className="call-button">
                    <div
                      className="col-md-12"
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontWeight: 500,
                      }}
                    >
                      BACK TO PAYMENT
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PaymentFail;

import React from "react";
import { getCardType, formatCardNumber } from "../../helpers/index";

export const CardItem = (props) => {
  return (
    <div className="card-item row">
      <div className="col-sm-2" style={{ paddingLeft: 0 }}>
        <img
          src={getCardType(props.data.cardNumber)}
          alt=""
          style={{ width: "80%" }}
        />
      </div>
      <div
        className="col-sm-8"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ letterSpacing: 2 }}>
          {formatCardNumber(props.data.cardNumber)}
        </span>
      </div>
      <div className="col-sm-2" style={{ paddingRight: 0, textAlign: "right" }}>
        <label>
          <input
            type="radio"
            name="payment"
            value="transfer"
            onChange={props.onSelect}
            checked={props.selected}
          />
        </label>
      </div>
    </div>
  );
};

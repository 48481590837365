import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ReservationActions from "../../redux/actions/reservation";
import moment from "moment";
import TimeSelector from "./TimeSelector";

class TimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickupTime: this.props.reservation.Date,
      returnTime: Date.now(),
      minTime: null,
      maxTime: null,
      fullDaySelected: false,
      fullDayDisabled: false,
      halfDayDisabled: false,
      halfDaySelected: false,
      amount: 1,
      guests: [
        { value: 1, isActive: true },
        { value: 2, isActive: false },
        { value: 3, isActive: false },
        { value: 4, isActive: false },
        { value: 5, isActive: false },
        { value: 6, isActive: false },
        { value: 7, isActive: false },
        { value: 8, isActive: false },
      ],
      showWarning: false,
    };
  }

  componentWillMount() {
    this.setupTime();
  }

  setupTime() {
    let selectedDate = new Date(this.props.reservation.Date);
    let minTime = new Date(
      new Date(this.props.reservation.Date).setHours(7, 0, 0, 0)
    );
    let maxTime = new Date(
      new Date(this.props.reservation.Date).setHours(19, 0, 0, 0)
    );

    let today = new Date();
    let checkToday = selectedDate.getTime() === new Date().setHours(0, 0, 0, 0);

    if (checkToday) {
      selectedDate.setHours(
        today.getMinutes() > 30 ? today.getHours() + 1 : today.getHours()
      );
      selectedDate.setMinutes(today.getMinutes() > 30 ? 0 : 30);
      selectedDate.setSeconds(0);
    } else {
      selectedDate = new Date(selectedDate.setHours(12, 0, 0, 0));
    }

    this.setTime(selectedDate);

    this.setState({ pickupTime: selectedDate, minTime, maxTime });
  }

  setTime = (pickupTime) => {
    let selected = new Date(pickupTime);
    let current = selected.getTime();
    let twelvePm = new Date(selected.setHours(12, 0, 0, 0)).getTime();
    let fourPm = new Date(selected.setHours(16, 0, 0, 0)).getTime();
    let eightPm = new Date(selected.setHours(16, 0, 0, 0)).getTime();

    if (current > twelvePm) {
      if (current > fourPm) {
        this.setState({
          fullDayDisabled: true,
          halfDayDisabled: current > eightPm,
          fullDaySelected: false,
          halfDaySelected: false,
          showWarning: false,
        });
      } else {
        this.setState({
          fullDayDisabled: true,
          halfDayDisabled: false,
          fullDaySelected: false,
          halfDaySelected: false,
          showWarning: false,
        });
      }
    } else {
      this.setState({
        fullDayDisabled: false,
        halfDayDisabled: false,
        fullDaySelected: false,
        halfDaySelected: false,
        showWarning: false,
      });
    }

    this.setState({ pickupTime });
  };

  setFullday = (fullDaySelected) => {
    const { pickupTime } = this.state;

    let eightPm = new Date(pickupTime);
    let returnTime = new Date(pickupTime);

    eightPm = new Date(eightPm.setHours(20, 0, 0, 0));
    returnTime = new Date(returnTime.setHours(returnTime.getHours() + 8));

    let warn = returnTime.getTime() > eightPm.getTime();

    if (warn) {
      alert(
        "The latest drop off time is 8:00PM.\nTo make a special request, please contact your concierge or email \nsales@saxdorturkey.com"
      );
    }

    this.setState({
      fullDaySelected,
      returnTime,
      halfDaySelected: false,
      showWarning: warn,
    });
  };

  setHalfday = (halfDaySelected) => {
    const { pickupTime } = this.state;

    let eightPm = new Date(pickupTime);
    let returnTime = new Date(pickupTime);

    eightPm = new Date(eightPm.setHours(20, 0, 0, 0));
    returnTime = new Date(
      returnTime.setHours(new Date(pickupTime).getHours() + 4)
    );

    let warn = returnTime.getTime() > eightPm.getTime();

    if (warn) {
      alert(
        "The latest drop off time is 8:00PM.\nTo make a special request, please contact your concierge or email \nsales@saxdorturkey.com"
      );
    }

    this.setState({
      halfDaySelected,
      returnTime,
      fullDaySelected: false,
      showWarning: warn,
    });
  };

  setGuestAmount = (index) => {
    const { guests } = this.state;
    guests.forEach((g, i) => {
      g.isActive = false;
    });
    guests[index].isActive = true;
    this.setState({ guests, amount: index + 1 });
  };

  onSelectPickup = () => {
    const {
      fullDaySelected,
      halfDaySelected,
      pickupTime,
      returnTime,
      amount,
    } = this.state;
    if (!fullDaySelected && !halfDaySelected) {
      alert("Please select Half or Full Day.");
      return;
    }

    let pickupObj = {
      Date: moment(pickupTime).format("YYYY-MM-DD"),
      Hour: moment(pickupTime).format("HH:mm"),
    };

    let returnObj = {
      Date: moment(returnTime).format("YYYY-MM-DD"),
      Hour: moment(returnTime).format("HH:mm"),
    };

    this.props.setGuests(amount);
    this.props.setTime(pickupObj, returnObj, fullDaySelected);
    this.props.history.push("/boat-select");
  };

  get timePeriod() {
    const { reservation } = this.props;
    return reservation.Detail.IsFullDay ? "FULL DAY" : "HALF DAY";
  }

  render() {
    const {
      pickupTime,
      fullDaySelected,
      halfDaySelected,
      fullDayDisabled,
      halfDayDisabled,
      guests,
    } = this.state;
    const { reservation } = this.props;

    return (
      <div>
        <section id="time-picker">
          <div className="container">
            <h1>
              {" "}
              <span className="step-number">2-</span> SELECT DURATION
            </h1>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <TimeSelector onClick={this.setTime} />
              </div>
              <div className="col-md-6 col-sm-12">
                <span className="selected-date">
                  {moment(reservation.Date).format("ll")}
                </span>

                <span
                  className={`selected-day ${halfDayDisabled && "disabled"} ${
                    halfDaySelected && "active"
                  }`}
                  onClick={() => this.setHalfday(true)}
                >
                  HALF DAY
                  <br />
                  {moment(pickupTime).add(4, "hours").format("LT")}
                </span>
                <span
                  className={`selected-day ${fullDayDisabled && "disabled"} ${
                    fullDaySelected && "active"
                  }`}
                  onClick={() => this.setFullday(true)}
                >
                  FULL DAY
                  <br />
                  {moment(pickupTime).add(8, "hours").format("LT")}
                </span>
              </div>
            </div>
          </div>
        </section>
        <section id="guest-picker">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <h2>
                  <span className="step-number">3-</span> SELECT{" "}
                  <span style={{ color: "red", fontSize: 38 }}>#</span> OF
                  GUESTS
                </h2>
              </div>
              <div className="col-md-8 col-sm-12 text-center">
                {guests.map((Guest, i) => (
                  <span
                    key={i}
                    className={`${
                      Guest.isActive ? "guest-selected" : ""
                    } guest-numbers`}
                    onClick={() => this.setGuestAmount(i)}
                  >
                    {Guest.value}
                  </span>
                ))}
              </div>
            </div>
            <div className="container">
              <div className="col-md-12 text-center">
                <a onClick={this.onSelectPickup}>
                  <img
                    src={require("../../assets/images/next.png")}
                    alt=""
                    className="next-button"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reservation: state.reservationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ReservationActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TimePicker);

import React from "react";
import Layout from "../../components/common/Layout";
import DatePicker from "../../components/SelectTime/DatePicker";
import TimePicker from "../../components/SelectTime/TimePicker";

class Reservation extends React.Component {
  render() {
    return (
      <Layout nothome>
        <DatePicker />
        <TimePicker history={this.props.history} />
      </Layout>
    );
  }
}

export default Reservation;

import React from "react";
import ReservationDetail from "../../components/BoatSelect/ReservationDetail";
import AllBoats from "../../components/BoatSelect/AllBoats";
import Layout from "../../components/common/Layout";
import { connect } from "react-redux";
import { VDLoader } from "../../components/common/VDLoader";
import { bindActionCreators } from "redux";
import * as ReservationActions from "../../redux/actions/reservation";
import * as AppActions from "../../redux/actions/app";

class BoatSelect extends React.Component {
  componentDidMount() {
    const { reservation } = this.props;
    this.props.getBoats(
      reservation.HotspotId,
      reservation.Detail.PickupTime,
      reservation.Detail.ReturnTime,
    );
    this.props.resetLocations();
  }

  render() {
    const { boats } = this.props;

    if (boats.loading) return <VDLoader />;

    return (
      <Layout nothome>
        <ReservationDetail />
        <AllBoats />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    boats: state.appReducer.boats,
    location: state.appReducer.location,
    reservation: state.reservationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...ReservationActions, ...AppActions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BoatSelect);

import React from "react";
import { Carousel } from "react-bootstrap";

export const HowItWorks = () => {
  return (
    <div>
      <section id="how-it-works">
        <div className="container text-center">
          <h1>HOW IT WORKS</h1>
          <p>
            All around Turkey. We have selected the top destinations for you and
            your guests to ensure the most memorable Saxdor experience
            imaginable.
          </p>
        </div>
      </section>
      <section id="carousel">
        <Carousel
          nextIcon={
            <img
              width={24}
              height={24}
              src={require("../../assets/images/arrow-right.png")}
              alt="next slide"
            />
          }
          prevIcon={
            <img
              width={24}
              height={24}
              src={require("../../assets/images/arrow-left.png")}
              alt="previous slide"
            />
          }
          className="desktop-slider"
        >
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={require("../../assets/slider/saxdor_go.jpg")}
              alt="First slide"
            />
            <div className="carousel-text-left">
              <span className="red-text">HOW IT WORKS</span>
              <h1>Download the App</h1>
              <p>
                Click the Download on the App Store button here below, click to
                install and get ready to discover Saxdor Style.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={require("../../assets/slider/saxdor_go_confirm-instantly.jpg")}
              alt="Third slide"
            />
            <div className="carousel-text-left">
              <span className="red-text">HOW IT WORKS</span>
              <h1>Confirm Instantly</h1>
              <p>
                Book Your Reservation instantly, and get cruising at a moment's
                notice.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={require("../../assets/slider/saxdor_go_at_your_service.jpg")}
              alt="Third slide"
            />
            <div className="carousel-text-right">
              <span className="red-text">How It Works</span>
              <h1>At your Service</h1>
              <p>
                The Concierge service is available for Reservation guests to
                assist with your every need.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={require("../../assets/slider/saxdor_go_select_date.jpg")}
              alt="Third slide"
            />
            <div className="carousel-text-right">
              <span className="red-text">How It Works</span>
              <h1>Select Date & Time</h1>
              <p>
                Whether it's today, next month or next year, you'll find a
                Saxdor ready for your Full or Half Day charter.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={require("../../assets/slider/saxdor_go_hotspot.jpg")}
              alt="Third slide"
            />
            <div className="carousel-text-left">
              <span className="red-text">HOW IT WORKS</span>
              <h1 className="carousel__choose-your-hotspot">
                Choose your Hotspot
              </h1>
              <p style={{ width: "35%" }}>
                We have selected the top destinations for you and your guests to
                ensure the most memorable Saxdor experience imaginable. Click on
                your favorite Hotspot.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={require("../../assets/slider/saxdor_go_invite_guest.jpg")}
              alt="Third slide"
            />

            <div className="carousel-text-left">
              <span className="red-text">HOW IT WORKS</span>
              <h1>Invite Guests</h1>
              <p>
                Be the hero and invite your friends, family, collegues or just
                go solo, you decide.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={require("../../assets/slider/saxdor_go_locations.jpg")}
              alt="Third slide"
            />
            <div className="carousel-text-left">
              <span className="red-text">HOW IT WORKS</span>
              <h1 style={{ width: "50%" }}>Customize your Experience.</h1>
              <p style={{ width: "45%" }}>
                Saxdor GO gives you the exclusive opportunity to customize your
                pick-up/drop-off location at Marinas across Miami, as well as
                the option for private residence pick ups
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>

            <div className="carousel-text-right">
              <span className="red-text">KEY APP FEATURE</span>
              <span
                style={{ textAlign: "left", fontSize: 30, fontWeight: "bold" }}
              >
                Define your
              </span>
              <span
                style={{ textAlign: "left", fontSize: 30, fontWeight: "bold" }}
              >
                Pick-Up &
              </span>
              <span
                style={{ textAlign: "left", fontSize: 30, fontWeight: "bold" }}
              >
                Drop-Off
              </span>
              <span
                style={{ textAlign: "left", fontSize: 30, fontWeight: "bold" }}
              >
                Locations
              </span>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={require("../../assets/slider/saxdor_go_select_your_saxdor.jpg")}
              alt="Third slide"
            />
            <div className="carousel-text-right">
              <span className="red-text">How It Works</span>
              <h1>Select Your Saxdor</h1>
              <p>
                Click. Book. GO. Confirm your booking with the click of a button
                - simplicity has never been so at your leisure.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>
        </Carousel>

        <Carousel
          className="mobile-slider"
          nextIcon={
            <img
              width={24}
              height={24}
              src={require("../../assets/images/arrow-right.png")}
              alt="next slide"
            />
          }
          prevIcon={
            <img
              width={24}
              height={24}
              src={require("../../assets/images/arrow-left.png")}
              alt="previous slide"
            />
          }
        >
          <Carousel.Item>
            <img
              src={require("../../assets/slider/saxdor_go_mobile_download_the_app.jpg")}
              alt=""
            />
            <div className="slider-mobile-caption">
              <span className="red-text">HOW IT WORKS</span>
              <h2>Download the App</h2>
              <p>
                Click the Download on the App Store button here below, click to
                install and get ready to discover Saxdor Style.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={require("../../assets/slider/saxdor_go_mobile_create_your_account.jpg")}
              alt=""
            />
            <div className="slider-mobile-caption">
              <span className="red-text">HOW IT WORKS</span>
              <h2>Create your Account</h2>
              <p>
                Upload your favorite selfie, enter your email & mobile number
                and add a payment method for instant bookings.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={require("../../assets/slider/saxdor_go_mobile_locations.jpg")}
              alt=""
            />
            <div className="slider-mobile-caption">
              <span className="red-text">HOW IT WORKS</span>
              <h2>Choose your Hotspot</h2>
              <p>
                We have selected the top destinations for you and your guests to
                ensure the most memorable Saxdor experience imaginable. Click on
                your favorite Hotspot.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={require("../../assets/slider/saxdor_go_mobile_select_date.jpg")}
              alt=""
            />
            <div className="slider-mobile-caption">
              <span className="red-text">HOW IT WORKS</span>
              <h2>Select Date & Time</h2>
              <p>
                Whether it's today, next month or next year, you'll find a
                Saxdor ready for your Full or Half Day charter.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={require("../../assets/slider/saxdor_go_mobile_select_your_saxdor.jpg")}
              alt=""
            />
            <div className="slider-mobile-caption">
              <span className="red-text">HOW IT WORKS</span>
              <h2>Select your Saxdor</h2>
              <p>
                Click. Book. GO. Confirm your booking with the click of a button
                - simplicity has never been so at your leisure.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={require("../../assets/slider/saxdor_go_mobile_customize_your_experience.jpg")}
              alt=""
            />
            <div className="slider-mobile-caption">
              <span className="red-text">HOW IT WORKS</span>
              <h2>Customize your Experience.</h2>
              <p>
                Saxdor GO gives you the exclusive opportunity to customize your
                pick-up/drop-off location at Marinas across Miami, as well as
                the option for private residence pick ups
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={require("../../assets/slider/saxdor_go_mobile_confirm_instantly.jpg")}
              alt=""
            />
            <div className="slider-mobile-caption">
              <span className="red-text">HOW IT WORKS</span>
              <h2>Confirm Instantly</h2>
              <p>
                Book your reservation instantly, and get cruising at a moment’s
                notice.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={require("../../assets/slider/saxdor_go_mobile_invite_guest.jpg")}
              alt=""
            />
            <div className="slider-mobile-caption">
              <span className="red-text">HOW IT WORKS</span>
              <h2>Invite Guests</h2>
              <p>
                Be the hero and invite your friends, family, collegues or just
                go solo, you decide.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src={require("../../assets/slider/saxdor_go_mobile_service.jpg")}
              alt=""
            />
            <div className="slider-mobile-caption">
              <span className="red-text">HOW IT WORKS</span>
              <h2>At your Service</h2>
              <p>
                The Concierge service is available for reservation guests to
                assist with your every need.
              </p>
              <a rel="noopener noreferrer" href="#" target="_blank">
                <img
                  src={require("../../assets/images/app-store.png")}
                  alt=""
                  className="carousel-app-store"
                />
              </a>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    </div>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ReservationActions from '../../redux/actions/reservation';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { VDLoader } from '../common/VDLoader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

class DatePicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			months: 4,
			date: moment(),
			focused: true
		};
	}

	sizes = {
		xl: 1920,
		lg: 1100,
		m: 1600,
		sm: 768
	};

	windowCalculator(width) {
		if (width < this.sizes.sm) {
			this.setState({ months: 1 });
		} else if (width >= this.sizes.sm && width < this.sizes.lg) {
			this.setState({ months: 2 });
		} else if (width >= this.sizes.lg && width < this.sizes.m) {
			this.setState({ months: 3 });
		} else if (width >= this.sizes.m && width < this.sizes.xl) {
			this.setState({ months: 4 });
		}
	}

	componentDidMount() {
		this.windowCalculator(window.outerWidth);
	}

	onSelectDate = (date) => {
		const dateObj = date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
		this.setState({ date: dateObj }, () => {
			this.props.setDate(dateObj.toDate());
		});
	};

	onSelectHotspot = (hotspot) => {
		this.props.setHotspot(hotspot);
	};

	render() {
		const { months, focused, date } = this.state;
		const { hotspots, reservation } = this.props;
		const ActiveHotspotName = reservation.ActiveHotspot.Name;

		if (hotspots.loading) return <VDLoader />;
		return (
			<section id="date-picker">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="back-button">
								<Link to="/">
									<FontAwesomeIcon icon={faAngleLeft} /> Back
								</Link>
							</div>
						</div>
						<div className="col-md-6 col-sm-12">
							<h1>
								{' '}
								<span className="step-number">1-</span> SELECT RESERVATION DATE
							</h1>
						</div>
						<div className="col-md-6 col-sm-12">
							<DropdownButton id="hotspot-dropdown" title={ActiveHotspotName}>
								{hotspots.items.map((r, i) => (
									<React.Fragment key={i}>
										<Dropdown.Item as="button" onClick={() => this.onSelectHotspot(r)}>
											{r.Name}
										</Dropdown.Item>
									</React.Fragment>
								))}
							</DropdownButton>
						</div>
					</div>
				</div>
				<div className="container-fluid text-center">
					<SingleDatePicker
						numberOfMonths={months}
						onDateChange={(date) => this.onSelectDate(date)}
						onFocusChange={() => this.setState({ focused })}
						focused={focused}
						date={date}
					/>
				</div>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		reservation: state.reservationReducer,
		hotspots: state.appReducer.hotspots
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(ReservationActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);

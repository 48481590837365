const INITIAL_STATE = {
  phone: null,
  member: null,
  token: null,
  title: null,
  validationMessage: null,
  loading: false,
  resend: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "AUTH_SET_PHONENUMBER":
      return Object.assign({}, state, {
        loading: true,
      });
    case "AUTH_SET_PHONENUMBER_OK":
      return Object.assign({}, state, {
        phone: action.payload.phoneNumber,
        title: action.title,
        resend: action.resend,
        loading: false,
      });
    case "AUTH_ACTIVATE_MEMBER":
      return Object.assign({}, state, {
        loading: true,
        resend: false,
      });
    case "AUTH_ACTIVATE_MEMBER_OK":
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("member", JSON.stringify(action.payload.member));

      return Object.assign({}, state, {
        member: action.payload.member,
        token: action.payload.token,
        code: null,
        phone: null,
        loading: false,
      });
    case "AUTH_NUMBERNOTFOUND":
      return Object.assign({}, state, {
        validationMessage:
          "This number is not registered to Saxdor Go. Please Register.",
        loading: false,
      });
    case "AUTH_CODENOTFOUND":
      return Object.assign({}, state, {
        validationMessage: "Code invalid or expired",
        loading: false,
      });
    case "AUTH_BADREQUEST":
      return Object.assign({}, state, {
        validationMessage: "Server Error",
        loading: false,
      });
    case "AUTH_ALREADYEXISTS":
      return Object.assign({}, state, {
        validationMessage:
          "You have an existing Saxdor Go account. Please Login instead.",
        loading: false,
      });
    case "AUTH_CLEARMESSAGE":
      return Object.assign({}, state, {
        validationMessage: null,
      });
    case "AUTH_LOGOUT":
      return INITIAL_STATE;
    default:
      return state;
  }
};

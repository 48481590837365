import React from "react";
import Layout from "../../components/common/Layout";
import Axios from "axios";
import { AppConfig } from "../../config/index";
import moment from "moment";

class MyCharter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upcoming: [],
      past: [],
      loading: true,
    };
  }

  componentDidMount() {
    // this.onInit();
  }

  // onInit = () => {
  //   localStorage.getItem('member').then((member) => {
  //     const authMember = JSON.parse(member);

  //     Axios.all([ this.getUpcomingReservations(authMember.id), this.getPastReservations(authMember.id) ]).then((r) => {
  //       this.setState({ upcoming: r[0].data.value, past: r[1].data.value, loading: false });
  //     });
  //   });
  // };

  getUpcomingReservations(memberID) {
    return Axios.get(
      `${AppConfig.ApiUrl}odata/reservations(${memberID})/UpcomingCharters?$expand=Detail($select=PickupTime,ReturnTime),Hotspot&$orderby=Date DESC`,
    );
  }

  getPastReservations(memberID) {
    return Axios.get(
      `${AppConfig.ApiUrl}odata/reservations(${memberID})/PastCharters?$expand=Detail($select=PickupTime,ReturnTime),Hotspot&$orderby=Date DESC`,
    );
  }

  get upcoming() {
    if (this.state.upcoming.length === 0)
      return <p>"You don't have any upcoming reservations."</p>;

    return this.state.upcoming.map((item, i) => {
      return (
        <div key={i}>
          <div>
            <p>{moment(item.Date).format("dddd MMM. D, YYYY")}</p>
            <p>
              {item.Hotspot.Name},{" "}
              {moment(
                item.Detail.PickupTime.Date + "T" + item.Detail.PickupTime.Hour,
              ).format("LT")}
              {" - "}
              {moment(
                item.Detail.ReturnTime.Date + "T" + item.Detail.ReturnTime.Hour,
              ).format("LT")}
            </p>
          </div>
          {/* <img
						style={styles.carouselItemTitleIcon}
						resizeMode="contain"
						source={require('../../assets/img/arrow-red.png')}
					/> */}
        </div>
      );
    });
  }

  get past() {
    if (this.state.past.length === 0)
      return <p> "You don't have any past reservations."</p>;

    return this.state.past.map((item, i) => {
      return (
        <div key={i}>
          <div>
            <p>{moment(item.Date).format("dddd MMM. D, YYYY")}</p>
            <p>
              {item.Hotspot.Name},{" "}
              {moment(
                item.Detail.PickupTime.Date + "T" + item.Detail.PickupTime.Hour,
              ).format("LT")}
              {" - "}
              {moment(
                item.Detail.ReturnTime.Date + "T" + item.Detail.ReturnTime.Hour,
              ).format("LT")}
            </p>
          </div>
          {/* <Image
						style={styles.carouselItemTitleIcon}
						resizeMode="contain"
						source={require('../../assets/img/arrow-gray.png')}
					/> */}
        </div>
      );
    });
  }
  render() {
    return (
      <Layout nothome>
        <section id="my-charters">
          <div className="container">
            <div className="col-md-8 col-sm-12 my-charters-box">
              <h1>MY RESERVATIONS</h1>
              <div className="row">
                <div className="col-md-5">
                  <span className="red-text">UPCOMING RESERVATIONS</span>
                </div>
                <div className="col-md-7">
                  <h2>FRIDAY, DECEMBER 14, 2019</h2>
                  <p>{`> Click to view Charter details`}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-5">
                  <span className="red-text">PAST RESERVATIONS</span>
                </div>
                <div className="col-md-7">
                  <h2 className="grey-text">FRIDAY, DECEMBER 14, 2019</h2>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-7 offset-md-5">
                  <h2 className="grey-text">FRIDAY, DECEMBER 14, 2019</h2>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-7 offset-md-5">
                  <h2 className="grey-text">FRIDAY, DECEMBER 14, 2019</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default MyCharter;

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as ReservationActions from "../../redux/actions/reservation";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

class ReservationDetail extends React.Component {
  get timePeriod() {
    const { reservation } = this.props;
    return reservation.Detail.IsFullDay ? "FULL DAY" : "HALF DAY";
  }

  get ActiveHotspotName() {
    const { reservation } = this.props;
    return reservation.ActiveHotspot.Name ? (
      <span className="bold-text">{reservation.ActiveHotspot.Name}</span>
    ) : (
      "not found"
    );
  }

  render() {
    const { reservation } = this.props;

    return (
      <section id="reservation-detail">
        <div className="container">
          <div className="col-md-12">
            <div className="back-button">
              <Link to="/reservation">
                <FontAwesomeIcon icon={faAngleLeft} /> Back
              </Link>
            </div>
          </div>
          <div className="col-md-12 col-sm-12">
            <div className="grey-border-button">
              {moment(reservation.Date).format("ll")}
            </div>
            <div className="grey-border-button">{this.timePeriod}</div>
            <p className="light-text">
              AVAILABLE SAXDOR IN {this.ActiveHotspotName}
            </p>

            {/* <p className="bold-text">{reservation.ActiveHotspot.Name}</p> */}
          </div>
          <hr />
          <h1>
            <span className="step-number">4-</span>SELECT YOUR YACHT
          </h1>
          <hr />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reservation: state.reservationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ReservationActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationDetail);

import React from "react";
import Layout from "../components/common/Layout";

class DownloadApp extends React.Component {
  render() {
    return (
      <Layout nothome>
        <section id="download">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 download-app-box">
                <div className="col-md-12 col-sm-12">
                  <span className="red-text">DOWNLOAD THE APP</span>
                </div>
                <div className="col-md-12 col-sm-12">
                  <h1>NOW AVAILABLE IN THE APP STORE</h1>
                </div>
                <div className="col-md-12 col-sm-12">
                  <p>
                    Have a Saxdor On-Demand in the palm of your hand from
                    anywhere in the world. Download it today and get cruising!
                  </p>
                </div>
                <div className="col-md-12 col-sm-12">
                  <a rel="noopener noreferrer" href="#" target="_blank">
                    <img
                      src={require("../assets/images/app-store.png")}
                      alt=""
                      className="download-app-img"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img
                  src={require("../assets/images/download-app.png")}
                  alt=""
                  className="app-with-hand"
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default DownloadApp;

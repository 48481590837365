import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/common/Layout";

class PaymentSuccess extends React.Component {
  render() {
    return (
      <Layout nothome>
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <h1>PAYMENT CONFIRMED</h1>
              <p style={{ color: "grey" }}>
                <strong>
                  You're all set to cruise in Saxdor style! <br />
                  You will receive a confirmation email with your reservation
                  details shortly.
                </strong>
              </p>
              <div className="col-md-6 col-sm-12 offset-md-3">
                <div className="row">
                  <Link to="/my-reservations" className="call-button">
                    <div
                      className="col-md-12"
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontWeight: 500,
                      }}
                    >
                      MY RESERVATIONS
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PaymentSuccess;

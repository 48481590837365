import Header from "./Header";
import Footer from "./Footer";
import React, { Component } from "react";

class Layout extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { nothome } = this.props;
    return (
      <React.Fragment>
        <Header />
        <main className={`main ${nothome ? "not-home" : "home"}`}>
          {this.props.children}
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Layout;

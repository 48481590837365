import React from "react";

export class Landing extends React.Component {
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.mute();
  }

  _onEnd(event) {
    event.target.playVideo();
  }

  render() {
    return (
      <section className="landing">
        <div className="landing__booking-options-section">
          <h4 className="landing__booking-description">
            Book Now With A Team Member
          </h4>
          <div className="landing__booking-option">
            <a
              className="landing__booking-option-link"
              href="tel:+905308857446"
              target="_blank"
            >
              <img
                src={require("../../assets/images/phone-icon.png")}
                alt="book by phone"
              />
              <p className="landing__booking-option-text">+90 530 885 74 46</p>
            </a>
          </div>
          <div className="landing__booking-option">
            <a
              className="landing__booking-option-link"
              href="sms:+905308857446"
              target="_blank"
            >
              <img
                src={require("../../assets/images/sms-icon.png")}
                alt="Book Via Text"
              />
              <p className="landing__booking-option-text">Book Via Text</p>
            </a>
          </div>
          <div className="landing__booking-option">
            <a
              className="landing__booking-option-link"
              href="mailto:sales@saxdorturkey.com"
              target="_blank"
            >
              <img
                src={require("../../assets/images/email-icon.png")}
                alt="Book Via Text"
              />
              <p className="landing__booking-option-text">
                sales@saxdorturkey.com
              </p>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

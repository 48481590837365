import React from "react";
import { Link as RLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ReservationActions from "../../redux/actions/reservation";
import * as AuthActions from "../../redux/actions/auth";
import { scroller } from "react-scroll";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

class Header extends React.Component {
  state = {
    authenticated: false,
    member: null,
    mobileMenuActive: false,
    dropdownActive: false,
  };

  componentWillMount() {
    const token = localStorage.getItem("token");
    const member = JSON.parse(localStorage.getItem("member"));
    this.setState({ authenticated: token != null, member });
  }

  scrollTo(e, element_id) {
    e.preventDefault();
    this.props.history.push("/");

    scroller.scrollTo(element_id, {
      duration: 600,
      delay: 200,
      smooth: "easeInOutQuart",
    });
  }

  onSelectHotspot = (e, hotspot) => {
    e.preventDefault();
    this.props.setHotspot(hotspot);
    this.props.history.push("/reservation");
  };

  onLogout = () => {
    localStorage.removeItem("token");
    Axios.defaults.headers.common["Authorization"] = null;

    setTimeout(() => {
      window.location.href = "/";
    }, 200);
  };

  componentWillUnmount() {
    this.setState({ mobileMenuActive: false });
  }

  render() {
    const { authenticated } = this.state;
    const { hotspots } = this.props;

    return (
      <header className="header">
        <div className="logo">
          <RLink to="/">
            <img
              src={require("../../assets/logo.svg")}
              alt=""
              className="logo__image"
            />
          </RLink>
        </div>
        <nav className="ct-navbar">
          <ul className="ct-navbar__navigation">
            <div className="ct-navbar__navigation-section">
              <li className="ct-navbar__navigation-item">
                <span href="#" className="ct-navbar__navigation-link">
                  Book In Your City
                </span>
                <div className="ct-navbar__navigation-expanded-area">
                  <ul className="ct-navbar__navigation-expanded-area-list">
                    <li className="ct-navbar__navigation-expanded-area-item">
                      {hotspots &&
                        hotspots.items != null &&
                        hotspots.items.map((hotspot, i) => (
                          <RLink
                            key={i}
                            to="/"
                            className="ct-navbar__navigation-expanded-area-link"
                            onClick={(e) => this.onSelectHotspot(e, hotspot)}
                          >
                            {hotspot.Name}
                          </RLink>
                        ))}
                    </li>
                  </ul>
                </div>
              </li>
              <li className="ct-navbar__navigation-item">
                <RLink
                  className="ct-navbar__navigation-link"
                  to="/"
                  onClick={(e) => this.scrollTo(e, "how-it-works")}
                >
                  How It Works
                </RLink>
              </li>
              <li className="ct-navbar__navigation-item">
                <RLink
                  className="ct-navbar__navigation-link"
                  onClick={() =>
                    (window.location.href = "https://saxdorturkey.com/blog")
                  }
                >
                  Blog
                </RLink>
              </li>
            </div>
            <div className="ct-navbar__navigation-section">
              {authenticated ? (
                <React.Fragment>
                  <li className="ct-navbar__navigation-item ml-auto">
                    <RLink
                      to="/reservation"
                      className="ct-navbar__navigation-link"
                    >
                      Book Now
                    </RLink>
                  </li>
                  <li className="ct-navbar__navigation-item ml-auto">
                    <RLink
                      onClick={this.onLogout}
                      className="ct-navbar__navigation-link"
                    >
                      Logout
                    </RLink>
                  </li>
                </React.Fragment>
              ) : (
                <li className="ct-navbar__navigation-item ml-auto">
                  <RLink to="/login" className="ct-navbar__navigation-link">
                    Sign In
                  </RLink>
                </li>
              )}
              <li className="ct-navbar__navigation-item ct-navbar__app-store-link">
                <a
                  rel="noopener noreferrer"
                  href="#"
                  target="_blank"
                >
                  <img
                    src={require("../../assets/images/app-store.png")}
                    alt="saxdorgo app"
                    style={{ width: 95, height: 30 }}
                  />
                </a>
              </li>
            </div>
          </ul>
        </nav>
        <div
          onClick={() =>
            this.setState((cs) => ({ mobileMenuActive: !cs.mobileMenuActive }))
          }
          className="ct-mobile-navbar__toggle"
        >
          <FontAwesomeIcon icon={faBars} size="2x" />
        </div>
        <div
          className={`ct-mobile-navbar__navigation-wrapper ${
            this.state.mobileMenuActive ? "active" : "hidden"
          }`}
        >
          <div
            onClick={() =>
              this.setState((cs) => ({
                mobileMenuActive: !cs.mobileMenuActive,
              }))
            }
            className="ct-mobile-navbar__close"
          >
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </div>
          <nav className="ct-mobile-navbar">
            <ul className="ct-mobile-navbar__navigation">
              <li className="ct-mobile-navbar__navigation-item">
                <span
                  onClick={() =>
                    this.setState((cs) => ({
                      dropdownActive: !cs.dropdownActive,
                    }))
                  }
                  href="#"
                  className="ct-navbar__navigation-link"
                >
                  Book In Your City
                </span>
                {this.state.dropdownActive && (
                  <div className="ct-navbar__navigation-expanded-area">
                    <ul className="ct-navbar__navigation-expanded-area-list">
                      <li className="ct-navbar__navigation-expanded-area-item">
                        <RLink
                          to="/book/miami"
                          className="ct-navbar__navigation-expanded-area-link"
                        >
                          Miami, FL
                        </RLink>
                        <RLink
                          to="/book/miami"
                          className="ct-navbar__navigation-expanded-area-link"
                        >
                          The Hamptons, NY
                        </RLink>
                        <RLink
                          to="/book/miami"
                          className="ct-navbar__navigation-expanded-area-link"
                        >
                          Fort Lauderdale, FL
                        </RLink>
                        <RLink
                          to="/book/miami"
                          className="ct-navbar__navigation-expanded-area-link"
                        >
                          Boca Raton, FL
                        </RLink>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <li className="ct-mobile-navbar__navigation-item">
                <RLink
                  className="ct-navbar__navigation-link"
                  to="/"
                  onClick={(e) => this.scrollTo(e, "how-it-works")}
                >
                  How It Works
                </RLink>
              </li>

              <li className="ct-navbar__navigation-item">
                <RLink
                  className="ct-navbar__navigation-link"
                  onClick={() =>
                    (window.location.href = "https://saxdorgo.com/blog")
                  }
                >
                  Blog
                </RLink>
              </li>
              {authenticated ? (
                <React.Fragment>
                  <li className="ct-navbar__navigation-item">
                    <RLink
                      to="/reservation"
                      className="ct-navbar__navigation-link"
                    >
                      Book Now
                    </RLink>
                  </li>
                  <li className="ct-navbar__navigation-item ml-auto">
                    <RLink
                      onClick={this.onLogout}
                      className="ct-navbar__navigation-link"
                    >
                      Logout
                    </RLink>
                  </li>
                </React.Fragment>
              ) : (
                <li className="ct-navbar__navigation-item">
                  <RLink to="/login" className="ct-navbar__navigation-link">
                    Sign In
                  </RLink>
                </li>
              )}
              <li className="ct-mobile-navbar__navigation-item ct-navbar__app-store-link">
                <a
                  rel="noopener noreferrer"
                  href="#"
                  target="_blank"
                >
                  <img
                    src={require("../../assets/images/header-app-store.png")}
                    alt="saxdorgo app"
                  />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    hotspots: state.appReducer.hotspots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...ReservationActions, ...AuthActions },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

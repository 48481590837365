import React from "react";
import ScrollArea from "react-scrollbar";

class TimeSelector extends React.Component {
  constructor() {
    super();

    this.state = {
      times: [
        {
          label: "07:00",
          value: new Date(),
          zone: "AM",
          isActive: false,
        },
        {
          label: "07:30",
          value: new Date(),
          zone: "AM",
          isActive: false,
        },
        {
          label: "08:00",
          value: new Date(),
          zone: "AM",
          isActive: false,
        },
        {
          label: "08:30",
          value: new Date(),
          zone: "AM",
          isActive: false,
        },
        {
          label: "09:00",
          value: new Date(),
          zone: "AM",
          isActive: false,
        },
        {
          label: "09:30",
          value: new Date(),
          zone: "AM",
          isActive: false,
        },
        {
          label: "10:00",
          value: new Date(),
          zone: "AM",
          isActive: false,
        },
        {
          label: "10:30",
          value: new Date(),
          zone: "AM",
          isActive: false,
        },
        {
          label: "11:00",
          value: new Date(),
          zone: "AM",
          isActive: false,
        },
        {
          label: "11:30",
          value: new Date(),
          zone: "AM",
          isActive: false,
        },
        {
          label: "12:00",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "12:30",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "01:00",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "01:30",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "02:00",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "02:30",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "03:00",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "03:30",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "04:00",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "04:30",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "05:00",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "05:30",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "06:00",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "06:30",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
        {
          label: "07:00",
          value: new Date(),
          zone: "PM",
          isActive: false,
        },
      ],
      selectedTime: null,
    };
  }

  timeSelected(i) {
    const { times } = this.state;
    times.map((time, index) => (time.isActive = false));
    times[i].isActive = true;
    this.setState({ times, selectedTime: times[i].value });
  }

  handleClick = (e, time, i) => {
    e.preventDefault();
    this.timeSelected(i);
    var parts = time.label.split(":");
    let hour =
      time.zone === "PM" && parts[0] !== "12"
        ? parseInt(parts[0]) + 12
        : parseInt(parts[0]);
    let minute = parts[1];
    let date = time.value.setHours(hour, minute, 0, 0);
    this.props.onClick(date);
  };

  render() {
    const { times } = this.state;
    return (
      <div className="time-selector">
        <ScrollArea
          speed={0.2}
          className="area"
          contentClassName="content"
          horizontal={false}
        >
          <div>
            {times.map((time, i) => (
              <a
                className={`${time.isActive ? "selected-time" : "passive"}`}
                onClick={(e) => this.handleClick(e, time, i)}
                key={i}
                style={{ display: "block" }}
                href=""
              >
                {`${time.label} ${time.zone}`}
              </a>
            ))}
          </div>
        </ScrollArea>
      </div>
    );
  }
}

export default TimeSelector;

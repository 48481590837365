const INITIAL_STATE = {
	member: null,
	hotspots: {
		items: null,
		loading: true
	},
	boats: {
		items: [],
		loading: false
	},
	location: {
		lat: 0,
		long: 0
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'GET_MEMBER':
			return {
				...state,
				member: action.payload
			};
		case 'GET_HOTSPOTS':
			return Object.assign({}, state, {
				hotspots: {
					items: action.payload,
					loading: false
				}
			});
		case 'GET_HOTSPOTS_BY_LOCATION':
			return Object.assign({}, state, {
				hotspots: {
					items: action.payload,
					loading: false
				}
			});
		case 'GET_HOTSPOTS_FAIL':
			return Object.assign({}, state, {
				hotspots: {
					items: null,
					loading: false
				}
			});
		case 'GET_BOATS':
			return Object.assign({}, state, {
				boats: {
					items: [],
					loading: true
				}
			});
		case 'GET_BOATS_OK':
			return Object.assign({}, state, {
				boats: {
					items: action.payload,
					loading: false
				}
			});
		case 'SET_LOCATION':
			return Object.assign({}, state, {
				location: {
					lat: action.payload.lat,
					long: action.payload.long
				}
			});
		default:
			return state;
	}
};
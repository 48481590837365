export function setMember(memberId) {
  return {
    type: 'SET_MEMBER',
    payload: memberId
  }
}

export function setHotspot(hotspot) {
  return {
    type: 'SET_HOTSPOT',
    payload: hotspot
  }
}

export function setDate(date) {
  return {
    type: 'SET_DATE',
    payload: date
  }
}

export function setTime(pickupTime, returnTime, isFullDay) {
  return {
    type: 'SET_TIME',
    payload: {
      if: isFullDay,
      pt: pickupTime,
      rt: returnTime
    }
  }
}

export function setGuests(count) {
  return {
    type: 'SET_GUESTS',
    payload: count
  }
}

export function setPrice(price, taxRate) {
  return {
    type: 'SET_PRICE',
    payload: { price, taxRate }
  }
}

export function setBoat(boat) {
  return {
    type: 'SET_BOAT',
    payload: boat
  }
}

export function resetLocations() {
  return {
    type: 'RESET_LOCATIONS'
  }
}

export function setPickupLocation(location, requested) {
	return {
		type: 'SET_PICKUP_LOCATION',
		payload: location,
		requested: requested ? '1' : '0'
	};
}

export function setReturnLocation(location, requested) {
	return {
		type: 'SET_RETURN_LOCATION',
		payload: location,
		requested: requested ? '1' : '0'
	};
}

export function setNotes(notes) {
  return {
    type: 'SET_NOTES',
    payload: notes
  }
}

export function completeReservation() {
  return {
    type: 'COMPLETE_RESERVATION',
    payload: null
  }
}
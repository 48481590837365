import React from "react";
import Axios from "axios";
import Home from "./Home";
import Login from "./auth/Login";
import Register from "./auth/Register";
import SmsVerification from "./auth/SmsVerification";
import CreateProfile from "./auth/CreateProfile";
import Reservation from "./reservation/Reservation";
import BoatSelect from "./reservation/BoatSelect";
import MyCharters from "./reservation/MyCharters";
import DownloadApp from "./DownloadApp";
import SelectedBoatDetail from "./reservation/SelectedBoatDetail";
import AcceptAndPay from "./reservation/AcceptAndPay";
import Payment from "./reservation/Payment";
import WireTransfer from "./reservation/WireTransfer";
import PaymentSuccess from "./reservation/PaymentSuccess";
import PaymentFail from "./reservation/PaymentFail";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

class Index extends React.Component {
  componentWillMount() {
    const token = localStorage.getItem("token");
    console.log(token);
    if (token != null || token !== undefined) {
      Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/login/" component={Login} />
          <Route path="/register/" component={Register} />
          <Route path="/sms-verification/" component={SmsVerification} />
          <Route path="/create-profile/" component={CreateProfile} />
          <Route path="/reservation/" component={Reservation} />
          <Route path="/boat-select/" component={BoatSelect} />
          <Route path="/selected-boat-detail/" component={SelectedBoatDetail} />
          <Route path="/accept-and-pay" component={AcceptAndPay} />
          <Route path="/my-reservations/" component={MyCharters} />
          <Route path="/download-app/" component={DownloadApp} />
          <Route path="/payment/" component={Payment} />
          <Route path="/wire-success/" component={WireTransfer} />
          <Route path="/payment-success/" component={PaymentSuccess} />
          <Route path="/payment-fail/" component={PaymentFail} />
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  }
}

export default Index;

import React from 'react';
import { Carousel } from 'react-bootstrap';
import { formatMoney } from '../../helpers';
import { AppConfig } from '../../config';
import { VDLoader } from '../common/VDLoader';

import * as AppActions from '../../redux/actions/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ReservationActions from '../../redux/actions/reservation';

class SingleBoat extends React.Component {
	state = {
		selectedBoat: false
	};

	get carouselItems() {
		const { boat } = this.props;

		return boat.Images.map((image, i) => {
			return (
				<Carousel.Item key={i}>
					<img alt={image.Image} className="d-block w-100" src={`${AppConfig.AssetUrl + image.Image}`} />
				</Carousel.Item>
			);
		});
	}

	render() {
		const { boat, reservation } = this.props;
		if (boat.loading) return <VDLoader />;
		return (
			<div className="col-md-6 col-sm-12">
				<div className="boat-item">
					<div className="price">
						{reservation.Detail.IsFullDay ? (
							<React.Fragment>
								<span className="amount">{formatMoney(boat.Price.FullDayPrice)}</span>
								<span className="time-info">FULL DAY</span>
							</React.Fragment>
						) : (
							<React.Fragment>
								<span className="amount">{formatMoney(boat.Price.HalfDayPrice)}</span>
								<span className="time-info">HALF DAY</span>
							</React.Fragment>
						)}
					</div>
					<p className="red-text">0.2 MILES AWAY</p>
					<p className="bold-text">
						{boat.Make} {boat.Model}
					</p>
					<p className="item-info">
						<span className="item-property">Color:</span>
						{boat.Detail.HullColor}
					</p>
					<p className="item-info">
						<span className="item-property">Cushions:</span>
						{boat.Detail.CushionColor}
					</p>
					{/* <p className="item-info">
            <span className="item-property">Toys:</span>Seabob, Standub Paddle Boards, Snorkel Equipment, DJ Booth
          </p> */}
					{boat.Images.length > 0 ? (
						<Carousel
							nextIcon={
								<img
									width={24}
									height={24}
									src={require('../../assets/images/arrow-right.png')}
									alt="next slide"
								/>
							}
							prevIcon={
								<img
									width={24}
									height={24}
									src={require('../../assets/images/arrow-left.png')}
									alt="previous slide"
								/>
							}
						>
							{this.carouselItems}
						</Carousel>
					) : (
						<img
							className="d-block w-100"
							src={`${AppConfig.AssetUrl + boat.CoverImage}`}
							style={{ borderRadius: 30, marginTop: 20 }}
							alt={boat.CoverImage}
						/>
					)}
				</div>
				<a className="select-boat-button" onClick={this.props.onClick}>
					select
				</a>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		boats: state.appReducer.boats,
		location: state.appReducer.location,
		reservation: state.reservationReducer
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ ...ReservationActions, ...AppActions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleBoat);

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ReservationActions from "../../redux/actions/reservation";
import { VDLoader } from "../../components/common/VDLoader";
import { AppConfig } from "../../config/index";

class SelectHotspot extends React.Component {
  componentDidMount() {
    const { hotspots } = this.props;

    if (hotspots)
      hotspots.map((item) => (item.CoverImage = encodeURI(item.CoverImage)));
  }

  onSelectHotspot = (hotspot) => {
    this.props.setHotspot(hotspot);
    this.props.history.push("/reservation");
  };

  render() {
    const { hotspots } = this.props;
    if (hotspots == null) return <VDLoader />;

    return (
      <section id="select-hotspot">
        <div className="container">
          <h2 className="hotspots-header">Choose Your City</h2>
          <p>
            Select a location to view and reserve Saxdor boats available in
            your area.
          </p>
        </div>

        <div className="hotspots">
          {hotspots &&
            hotspots.map((hotspot, i) => (
              <div
                key={i}
                className={`hotspot-item ${
                  i & (2 > 0) && "hotspot-item-second"
                }`}
                style={{ backgroundImage: `url(${AppConfig.AssetUrl}${hotspot.CoverImage})`}}
                onClick={(e) => this.onSelectHotspot(hotspot)}
              >
                {/* <img
                  src={`${AppConfig.AssetUrl}${hotspot.CoverImage}`}
                  alt={hotspot.Name}
                /> */}
                <span className="hotspot-name">{hotspot.Name}</span>
                {hotspot.Boats && (
                  <span className="hotspot-available-options">
                    {hotspot.Boats.length} Options
                  </span>
                )}
              </div>
            ))}
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...ReservationActions }, dispatch);
};

export default withRouter(connect(null, mapDispatchToProps)(SelectHotspot));

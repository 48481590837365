const INITIAL_STATE = {
	MemberId: 0,
	HotspotId: 0,
	BoatId: 0,
	Date: new Date(),
	Type: '1',
	Status: '0',
	Detail: {
		PickupTime: {
			Date: '',
			Hour: ''
		},
		ReturnTime: {
			Date: '',
			Hour: ''
		},
		IsFullDay: false,
		PickupRequested: '0',
		DropOffLocation: null,
		DropOffRequested: '0',
		Price: 0,
		TaxRate: 0,
		Notes: null
	},
	GuestAmount: 1,
	ActiveBoat: null,
	ActiveHotspot: null
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_MEMBER':
			return Object.assign({}, state, {
				MemberId: action.payload
			});
		case 'SET_HOTSPOT':
			return Object.assign({}, state, {
				HotspotId: action.payload.Id,
				ActiveHotspot: action.payload
			});
		case 'SET_DATE':
			return Object.assign({}, state, {
				Date: action.payload
			});
		case 'SET_TIME':
			return Object.assign({}, state, {
				Detail: Object.assign({}, state.Detail, {
					PickupTime: Object.assign({}, state.PickupTime, {
						Date: action.payload.pt.Date,
						Hour: action.payload.pt.Hour
					}),
					ReturnTime: Object.assign({}, state.ReturnTime, {
						Date: action.payload.rt.Date,
						Hour: action.payload.rt.Hour
					}),
					IsFullDay: action.payload.if
				})
			});
		case 'SET_GUESTS':
			return Object.assign({}, state, {
				GuestAmount: action.payload
			});
		case 'SET_PRICE':
			return Object.assign({}, state, {
				Detail: Object.assign({}, state.Detail, {
					Price: action.payload.price,
					TaxRate: action.payload.taxRate
				})
			});
		case 'SET_BOAT':
			return Object.assign({}, state, {
				BoatId: action.payload.Id,
				ActiveBoat: action.payload
			});
		case 'SET_PICKUP_LOCATION':
			return Object.assign({}, state, {
				Detail: Object.assign({}, state.Detail, {
					PickupLocation: Object.assign({}, state.PickupLocation, {
						Latitude: action.payload.Latitude,
						Longitude: action.payload.Longitude
					}),
					PickupRequested: action.requested
				})
			});
		case 'SET_RETURN_LOCATION':
			return Object.assign({}, state, {
				Detail: Object.assign({}, state.Detail, {
					DropOffLocation: Object.assign({}, state.DropOffLocation, {
						Latitude: action.payload.Latitude,
						Longitude: action.payload.Longitude
					}),
					DropOffRequested: action.requested
				})
			});
		case 'RESET_LOCATIONS':
			return Object.assign({}, state, {
				Detail: Object.assign({}, state.Detail, {
					PickupLocation: null,
					DropOffLocation: null,
					PickupRequested: '0',
					DropOffRequested: '0'
				})
			});
		case 'SET_NOTES':
			return Object.assign({}, state, {
				Detail: Object.assign({}, state.Detail, {
					Notes: action.payload
				})
			});
		case 'COMPLETE_RESERVATION':
			return Object.assign({}, state, {
				HotspotId: 0,
				BoatId: 0,
				Date: new Date(),
				Detail: Object.assign({}, state.Detail, {
					PickupTime: Object.assign({}, state.PickupTime, {
						Date: '',
						Hour: ''
					}),
					ReturnTime: Object.assign({}, state.ReturnTime, {
						Date: '',
						Hour: ''
					}),
					PickupLocation: null,
					DropOffLocation: null,
					PickupRequested: '0',
					DropOffRequested: '0',
					Notes: null
				})
			});
		default:
			return state;
	}
};

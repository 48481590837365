import React from "react";
import Layout from "../../components/common/Layout";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { AppConfig } from "../../config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ReservationActions from "../../redux/actions/reservation";
import GoogleMapReact from "google-map-react";

import LocationPicker from "react-location-picker";
import Geosuggest from "react-geosuggest";
import { Modal, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

class BoatDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      pickUpLocation: {
        lat: this.props.reservation.ActiveBoat.Location.Latitude,
        lng: this.props.reservation.ActiveBoat.Location.Longitude,
        address: "",
      },
      dropOffLocation: {
        lat: this.props.reservation.ActiveBoat.Location.Latitude,
        lng: this.props.reservation.ActiveBoat.Location.Longitude,
        address: "",
      },

      tmpLocation: {
        lat: this.props.reservation.ActiveBoat.Location.Latitude,
        lng: this.props.reservation.ActiveBoat.Location.Longitude,
        address: "",
      },
      showPickUp: false,
      showDropOff: false,
      activeImage: this.props.reservation.ActiveBoat.CoverImage,
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    this.setState({ authenticated: token != null });
    this.setLocations();
  }

  setLocations() {
    const { reservation } = this.props;

    if (reservation.Detail.PickupLocation == null) {
      this.props.setPickupLocation({
        Latitude: reservation.ActiveBoat.Location.Latitude,
        Longitude: reservation.ActiveBoat.Location.Longitude,
      });
    } else {
      this.setState({
        pickUpLocation: {
          ...this.state.pickUpLocation,
          lat: reservation.Detail.PickupLocation.Latitude,
          lng: reservation.Detail.PickupLocation.Longitude,
        },
      });
    }

    if (reservation.Detail.DropOffLocation == null) {
      this.props.setReturnLocation({
        Latitude: reservation.ActiveBoat.Location.Latitude,
        Longitude: reservation.ActiveBoat.Location.Longitude,
      });
    } else {
      this.setState({
        dropOffLocation: {
          ...this.state.dropOffLocation,
          lat: reservation.Detail.DropOffLocation.Latitude,
          lng: reservation.Detail.DropOffLocation.Longitude,
        },
      });
    }
  }

  get timePeriod() {
    const { reservation } = this.props;
    return reservation.Detail.IsFullDay ? "FULL DAY" : "HALF DAY";
  }

  get boatImages() {
    const { reservation } = this.props;

    return reservation.ActiveBoat.Images
      ? reservation.ActiveBoat.Images.map((image, i) => (
          <div className="col-md-4" key={i}>
            <img
              src={AppConfig.ApiUrl + image.Image}
              alt=""
              className="img"
              style={{ cursor: "pointer" }}
            />
          </div>
        ))
      : "";
  }

  get boatImagesClickable() {
    const { reservation } = this.props;

    return (
      reservation.ActiveBoat.Images && (
        <div style={{ width: '100%' }}>
          {/* <img src={AppConfig.ApiUrl + this.state.activeImage} alt="" /> */}
          <div
            style={{
              background: `url(${AppConfig.ApiUrl + this.state.activeImage})`,
            }}
            className="boat-image-slider"
          />
          <div className="row">
            {reservation.ActiveBoat.Images.map((image, i) => (
              <div className="col-md-4" key={i}>
                <img
                  src={AppConfig.ApiUrl + image.Image}
                  alt=""
                  onClick={() => this.setState({ activeImage: image.Image })}
                  className="img"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
          </div>
        </div>
      )
    );
  }

  handleLocationChange(location) {
    this.setState({ tmpLocation: location.position });
  }

  onSuggestSelect(suggest) {
    var newLat;
    var newLng;
    var newAddress;
    if (suggest !== undefined) {
      if (suggest.location.lat != null && suggest.location.lng != null) {
        newLat = suggest.location.lat;
        newLng = suggest.location.lng;
        newAddress = suggest.label;

        let location = {
          position: {
            lat: newLat,
            lng: newLng,
            address: newAddress,
          },
          places: null,
        };

        this.handleLocationChange(location);
      }
    }
  }

  selectPickupLocation = () => {
    const { tmpLocation } = this.state;
    const { reservation } = this.props;

    if (
      reservation.Detail.PickupLocation.Latitude.toFixed(7) !==
        tmpLocation.lat.toFixed(7) &&
      reservation.Detail.PickupLocation.Longitude.toFixed(7) !==
        tmpLocation.lng.toFixed(7)
    ) {
      this.props.setPickupLocation(
        {
          Latitude: tmpLocation.lat,
          Longitude: tmpLocation.lng,
        },
        true,
      );
    }

    this.setState({
      pickUpLocation: {
        ...this.state.pickUpLocation,
        lat: tmpLocation.lat,
        lng: tmpLocation.lng,
      },
      showPickUp: false,
    });

    // this.togglePickUpModal();
  };

  selectDropOffLocation = () => {
    const { tmpLocation } = this.state;
    const { reservation } = this.props;

    if (
      reservation.Detail.DropOffLocation.Latitude.toFixed(7) !==
        tmpLocation.lat.toFixed(7) &&
      reservation.Detail.DropOffLocation.Longitude.toFixed(7) !==
        tmpLocation.lng.toFixed(7)
    ) {
      this.props.setReturnLocation(
        {
          Latitude: tmpLocation.lat,
          Longitude: tmpLocation.lng,
        },
        true,
      );
    }

    this.setState({
      dropOffLocation: {
        ...this.state.dropOffLocation,
        lat: tmpLocation.lat,
        lng: tmpLocation.lng,
      },
      showDropOff: false,
    });
  };

  togglePickUpModal = () => {
    this.setState({ showPickUp: !this.state.showPickUp });
  };

  toggleDropOffModal = () => {
    this.setState({ showDropOff: !this.state.showDropOff });
  };

  get pickUpLocationPicker() {
    const { lat, lng } = this.state.tmpLocation;
    const google = window.google;
    return (lat != null) & (lng != null) ? (
      <React.Fragment>
        <Geosuggest
          ref={(el) => (this._geoSuggest = el)}
          placeholder="TYPE YOUR ADDRESS HERE"
          initialValue=""
          onSuggestSelect={(e) => this.onSuggestSelect(e)}
          location={new google.maps.LatLng(lat, lng)}
          radius="20"
        />
        <LocationPicker
          containerElement={<div style={{ height: "100%" }} />}
          mapElement={<div style={{ height: "400px" }} />}
          defaultPosition={{ lat, lng }}
          onChange={(e) => this.handleLocationChange(e)}
          className="location-picker"
          zoom={16}
        />
        {/* <p>{address}</p> */}
      </React.Fragment>
    ) : (
      "Loading.."
    );
  }

  get dropOffLocationPicker() {
    const { lat, lng } = this.state.tmpLocation;
    const google = window.google;

    return (lat != null) & (lng != null) ? (
      <React.Fragment>
        <Geosuggest
          ref={(el) => (this._geoSuggest = el)}
          placeholder="TYPE YOUR ADDRESS HERE"
          initialValue=""
          onSuggestSelect={(e) => this.onSuggestSelect(e)}
          location={new google.maps.LatLng(lat, lng)}
          radius="20"
        />
        <LocationPicker
          containerElement={<div style={{ height: "100%" }} />}
          mapElement={<div style={{ height: "400px" }} />}
          defaultPosition={{ lat, lng }}
          onChange={(e) => this.handleLocationChange(e)}
          className="location-picker"
          zoom={16}
        />
      </React.Fragment>
    ) : (
      "Loading..."
    );
  }

  render() {
    const { showPickUp, showDropOff } = this.state;
    const { reservation } = this.props;

    return (
      <Layout nothome>
        <section id="selected-boat-detail">
          <div className="container">
            <div className="col-md-12">
              <div className="back-button">
                <Link to="/boat-select">
                  <FontAwesomeIcon icon={faAngleLeft} /> Back
                </Link>
              </div>
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="grey-border-button">
                {moment(reservation.Date).format("ll")}
              </div>
              <div className="grey-border-button">{this.timePeriod}</div>
              <p className="light-text">YOU'VE SELECTED</p>
              <p className="bold-text">
                {`${reservation.ActiveBoat.Year} ${reservation.ActiveBoat.Make} ${reservation.ActiveBoat.Model}`}
              </p>
              <p className="item-info">
                <span className="item-property">Color:</span>
                {reservation.ActiveBoat.Detail.HullColor}
              </p>
              <p className="item-info">
                <span className="item-property">Cushions:</span>
                {reservation.ActiveBoat.Detail.CushionColor}
              </p>
            </div>
            <div className="col-md-12 col-sm-12 selected-boat-images">
              {/* <img src={`${AppConfig.AssetUrl + reservation.ActiveBoat.CoverImage}`} alt="" /> */}
              {reservation.ActiveBoat.Images && (
                <div className="row selected-boat-image-gallery">
                  {this.boatImagesClickable}
                </div>
              )}
            </div>
            <div className="row select-location">
              <br />
              <div className="col-md-12">
                <hr />
                <h1>
                  <span className="step-number">5-</span>SELECT PICK UP & DROP
                  OFF LOCATIONS
                </h1>
                <hr />
                <br />
              </div>
              <div className="col-md-6 col-sm-12">
                <p>
                  <span className="red-text">PICK UP</span> LOCATION
                </p>
                {reservation.ActiveBoat.Location.Address && (
                  <p>{reservation.ActiveBoat.Location.Address}</p>
                )}
                <div className="location-preview">
                  <GoogleMapReact
                    defaultCenter={{
                      lat: this.state.pickUpLocation.lat,
                      lng: this.state.pickUpLocation.lng,
                    }}
                    center={{
                      lat: this.state.pickUpLocation.lat,
                      lng: this.state.pickUpLocation.lng,
                    }}
                    defaultZoom={14}
                  >
                    <MapMarker
                      lat={this.state.pickUpLocation.lat}
                      lng={this.state.pickUpLocation.lng}
                      text={
                        <img
                          src={require("../../assets/images/marker.png")}
                          alt={this.state.pickUpLocation.address}
                          style={{ maxWidth: 100, width: 30 }}
                        />
                      }
                    />
                  </GoogleMapReact>
                </div>
                <div
                  className={`red-button ${
                    reservation.Detail.PickupRequested === "1" ? "active" : ""
                  }`}
                  onClick={this.togglePickUpModal}
                >
                  {reservation.Detail.PickupRequested === "1"
                    ? "NEW PICK UP LOCATION REQUESTED"
                    : "REQUEST NEW PICK UP LOCATION"}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <p>
                  <span className="red-text">DROP OFF</span> LOCATION
                </p>
                {reservation.ActiveBoat.Location.Address && (
                  <p>{reservation.ActiveBoat.Location.Address}</p>
                )}
                <div className="location-preview">
                  <GoogleMapReact
                    defaultCenter={{
                      lat: this.state.dropOffLocation.lat,
                      lng: this.state.dropOffLocation.lng,
                    }}
                    center={{
                      lat: this.state.dropOffLocation.lat,
                      lng: this.state.dropOffLocation.lng,
                    }}
                    defaultZoom={14}
                  >
                    <MapMarker
                      lat={this.state.dropOffLocation.lat}
                      lng={this.state.dropOffLocation.lng}
                      text={
                        <img
                          src={require("../../assets/images/marker.png")}
                          style={{ maxWidth: 100, width: 30 }}
                          alt={this.state.pickUpLocation.address}
                        />
                      }
                    />
                  </GoogleMapReact>
                </div>

                <div
                  className={`red-button ${
                    reservation.Detail.DropOffRequested === "1" ? "active" : ""
                  }`}
                  onClick={this.toggleDropOffModal}
                >
                  {reservation.Detail.DropOffRequested === "1"
                    ? "NEW DROP OFF LOCATION REQUESTED"
                    : "REQUEST NEW DROP OFF LOCATION"}
                </div>
              </div>
            </div>

            <div className="row text-center">
              <div
                onClick={() => this.props.history.push("/accept-and-pay")}
                className="review-charter-button"
              >
                <button>
                  <div className="review-charter-button-text">
                    Review Charter
                  </div>
                </button>
              </div>
            </div>
          </div>
          <Modal show={showPickUp} onHide={this.togglePickUpModal}>
            <Modal.Header closeButton>
              <Modal.Title>Request New Pick Up Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="location-picker">{this.pickUpLocationPicker}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.togglePickUpModal}>
                Close
              </Button>
              <Button variant="danger" onClick={this.selectPickupLocation}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showDropOff} onHide={this.toggleDropOffModal}>
            <Modal.Header closeButton>
              <Modal.Title>Request New Drop Off Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="location-picker">
                {this.dropOffLocationPicker}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.toggleDropOffModal}>
                Close
              </Button>
              <Button variant="danger" onClick={this.selectDropOffLocation}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
      </Layout>
    );
  }
}

export const MapMarker = ({ text }) => (
  <div
    style={{
      transform: "translate(-50%, -50%)",
    }}
  >
    {text}
  </div>
);

const mapStateToProps = (state) => {
  return {
    reservation: state.reservationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ReservationActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BoatDetail);

import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export const VDLoader = () => {
  return (
    <div id="vd-loader">
      <Loader
        type="Puff"
        color="#eb262f"
        height={100}
        width={100}
        timeout={5000}
        className="vd-loader-item"
      />
    </div>
  );
};

import React from "react";
import Layout from "../../components/common/Layout";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ReservationActions from "../../redux/actions/reservation";
import axios from "axios";
import Cards from "react-credit-cards";
import { CardItem } from "../../components/Payment/CardItem";
import { VDLoader } from "../../components/common/VDLoader";
import { formatMoney } from "../../helpers";
import { AppConfig } from "../../config";
import "react-credit-cards/es/styles-compiled.css";

class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      loading: false,
      member: null,
      number: "",
      name: "",
      expiry: "",
      cvc: "",
      focus: "",
      zipCode: "",
      transfer: true,
      addCard: false,
      selectedPm: null,
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    const member = JSON.parse(localStorage.getItem("member"));
    this.setState({ authenticated: token != null, member });
  }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength,
      );
    }
  };

  resetState = () => {
    this.setState({
      number: "",
      name: "",
      expiry: "",
      cvc: "",
      focus: "",
      zipCode: "",
      transfer: true,
      selectedPm: null,
    });
  };

  get paymentMethods() {
    const { member } = this.props;
    const { selectedPm } = this.state;

    if (member.paymentMethods.length === 0)
      return (
        <div className="col-md-6 col-sm-12 offset-md-3 text-center">
          <p style={{ fontWeight: "bold" }}>No payment method added</p>
        </div>
      );

    return member.paymentMethods.map((pm, i) => {
      const selected = selectedPm != null && pm.id === selectedPm.id;

      if (!pm.isDeleted) {
        return (
          <div className="col-md-6 offset-md-3 col-sm-12" key={i}>
            <CardItem
              data={pm}
              selected={selected}
              onSelect={() => this.onSelectPm(pm)}
            />
          </div>
        );
      }
    });
  }

  onSelectPm = (selectedPm) => {
    this.setState({ selectedPm });
  };

  onSaveCard = () => {
    const { member } = this.props;
    const { number, expiry, cvc, zipCode } = this.state;

    this.setState({ loading: true });

    if (number === "") {
      this.setState({ loading: false });
      alert("Warning", "Card Number is invalid.");
      return;
    } else if (expiry === "") {
      this.setState({ loading: false });
      alert("Warning", "Please fill the Expiry Date field.");
      return;
    } else if (cvc === "") {
      this.setState({ loading: false });
      alert("Warning", "Please fill the CVC/CCV field.");
      return;
    } else {
      axios
        .post(`${AppConfig.ApiUrl}api/members/AddPaymentMethod/${member.id}`, {
          memberId: member.id,
          type: "0",
          cardNumber: number,
          expiration: expiry,
          cvc: cvc,
          zipCode: zipCode,
        })
        .then((r) => {
          member.paymentMethods = r.data.paymentMethods;
          localStorage.setItem("member", JSON.stringify(member));

          this.setState(
            {
              loading: false,
              number: "",
              name: "",
              expiry: "",
              cvc: "",
              focus: "",
              zipCode: "",
              addCard: false,
            },
            () => {
              alert("Card added successfully");
            },
          );
        })
        .catch((e) => {
          this.setState({ loading: false });
          alert("An error has been occured");
        });
    }
  };

  onWire = () => {
    let payload = { ...this.props.reservation };
    payload.MemberId = this.props.member.id;
    delete payload.ActiveBoat;
    delete payload.ActiveHotspot;

    this.setState({ loading: true });

    axios
      .post(`${AppConfig.ApiUrl}odata/reservations/CompleteWire`, payload)
      .then((r) => {
        console.log(r);
        this.setState({ loading: false });
        this.props.completeReservation();
        this.props.history.push("/wire-success");
      })
      .catch((e) => {
        this.setState({ loading: false });
        alert("Reservation cannot be created.");
      });
  };

  onCC = () => {
    const { selectedPm } = this.state;

    if (selectedPm == null) {
      alert("Please select a payment method to proceed payment.");
      return;
    }

    let payload = { ...this.props.reservation };
    payload.MemberId = this.props.member.id;
    delete payload.ActiveBoat;
    delete payload.ActiveHotspot;

    let extra = (payload.Detail.Price * 3) / 100;
    let amount = payload.Detail.Price + extra;

    this.setState({ loading: true });

    axios
      .post(`${AppConfig.ApiUrl}api/payment/checkout`, {
        MemberId: payload.MemberId,
        PaymentMethodId: selectedPm.id,
        Amount: amount,
      })
      .then((r) => {
        axios
          .post(`${AppConfig.ApiUrl}odata/reservations/complete`, {
            Reservation: payload,
            PaymentId: r.data.id,
          })
          .then((resp) => {
            this.setState({ loading: false });
            this.props.completeReservation();
            this.props.history.push("/payment-success");
          })
          .catch((e) => {
            console.log(e);
            this.setState({ loading: false });
            alert("Reservation cannot be created.");
          });
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.history.push({
          pathname: "/payment-fail",
          state: {
            message: e.response.data.message,
          },
        });
      });
  };

  render() {
    const {
      authenticated,
      loading,
      transfer,
      number,
      cvc,
      expiry,
      focus,
    } = this.state;
    const { reservation } = this.props;

    if (loading) return <VDLoader />;

    if (authenticated) {
      let charterFee = reservation.Detail.Price;
      let convenienceFee = (charterFee * 3) / 100;
      // let tax = charterFee * reservation.Detail.TaxRate / 100;
      let total = charterFee + convenienceFee;
      let wireTransfer = charterFee;
      return (
        <Layout nothome>
          <section id="payment">
            <div className="container">
              <div className="prices-fees">
                <div className="row text-center">
                  <div className="col-12 total-fee text-center">AMOUNT DUE</div>
                  {!transfer ? (
                    <div className="credit-card-fee">
                      <div className="col-12 total-fee text-center">
                        {formatMoney(total)}
                      </div>
                      <div>
                        <p>
                          <strong> REMAINING BALANCE DUE :</strong>{" "}
                          {formatMoney(0)}
                        </p>
                        <p>
                          *Any remaining balance must be paid prior to day of
                          reservation.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="credit-card-fee">
                      <div className="col-12 total-fee text-center">
                        {formatMoney(0)}
                      </div>
                      <div>
                        <p>
                          <strong> REMAINING BALANCE DUE :</strong>{" "}
                          {formatMoney(wireTransfer)}
                        </p>
                        <p>
                          *Any remaining balance must be paid prior to day of
                          reservation.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12 offset-md-3">
                  <div className="select-payment">
                    <p>Select Payment Method</p>
                  </div>
                  <form action="" className="payment-methods">
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="payment"
                          value="transfer"
                          onChange={this.resetState}
                          checked={transfer}
                        />
                        PAY BY WIRE TRANSFER
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="payment"
                          value="credit-card"
                          onChange={() => this.setState({ transfer: false })}
                        />
                        CREDIT CARD
                        <br />
                      </label>
                    </div>
                  </form>
                </div>
              </div>
              {!this.state.transfer && (
                <div className="row">
                  <div className="col-md-6 offset-md-3 col-sm-12 card-info">
                    <h5 style={{ fontWeight: "bold", fontSize: "1rem" }}>
                      MY CARDS
                    </h5>
                  </div>
                  {this.paymentMethods}
                  <div className="col-md-6 offset-md-3 col-sm-12 card-info">
                    <div className="text-center" style={{ marginBottom: 15 }}>
                      <a
                        href=""
                        style={{ fontWeight: "600", color: "#007bff" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ addCard: true });
                        }}
                      >
                        + Add Payment Method
                      </a>
                    </div>
                    {this.state.addCard && (
                      <React.Fragment>
                        <Cards
                          cvc={cvc}
                          expiry={expiry}
                          focus={focus}
                          number={number}
                          focused={focus}
                          placeholders={""}
                        />
                        <br />
                        <form>
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                pattern="[0-9]*"
                                type="number"
                                name="number"
                                placeholder="CARD NUMBER"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                maxLength="16"
                                onInput={this.maxLengthCheck}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="text"
                                name="expiry"
                                placeholder="EXPIRY"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                maxLength="7"
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="number"
                                name="cvc"
                                placeholder="CVC/CCV"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                maxLength="4"
                                onInput={this.maxLengthCheck}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="number"
                                name="zipCode"
                                placeholder="ZIP CODE"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                maxLength="7"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 text-right">
                              <button
                                className="btn btn-form"
                                onClick={this.onSaveCard}
                              >
                                SAVE
                              </button>
                            </div>
                          </div>
                        </form>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  <img
                    src={require("../../assets/images/accept-pay.png")}
                    alt=""
                    className="img accept-pay-button"
                    onClick={transfer ? this.onWire : this.onCC}
                  />
                </div>
              </div>
              <br />
            </div>
          </section>
        </Layout>
      );
    } else
      return <Redirect to={`/login?callback=${window.location.pathname}`} />;
  }
}

const mapStateToProps = (state) => {
  return {
    member: state.appReducer.member,
    reservation: state.reservationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ReservationActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);

import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import Layout from "../../components/common/Layout";
import { AppConfig } from "../../config";

class CreateProfile extends React.Component {
  state = {
    loading: false,
    pp: "",
    firstname: "",
    lastname: "",
    email: "",
    member: null,
  };

  componentWillMount() {
    const token = localStorage.getItem("token");
    const member = JSON.parse(localStorage.getItem("member"));
    if (token != null) {
      Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      this.setState({ member });
    }
  }

  onUploadImage = (e) => {
    if (e.target.files == null) return;

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const fd = new FormData();
    fd.append("image", e.target.files[0]);

    this.setState({ loading: true });
    const { member } = this.state;

    Axios.post(
      `${AppConfig.ApiUrl}api/members/ProfilePicture`,
      {
        memberId: member.id,
        image: fd,
      },
      config,
    )
      .then((r) => {
        member.detail.profileImage = r.data;
        localStorage.setItem("member", JSON.stringify(member));
        this.setState({
          pp: `${AppConfig.AssetUrl + member.detail.profileImage}`,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  };

  validateEmail = (email) => {
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(email) === false;
  };

  onEdit = () => {
    const { member, firstname, lastname, email } = this.state;

    if (
      firstname.trim() === "" ||
      lastname.trim() === "" ||
      email.trim() === ""
    ) {
      alert("Please fill all fields");
      return;
    }

    if (this.validateEmail(email)) {
      alert("Please enter a valid email address");
      return;
    }

    this.setState({ loading: true });

    Axios.post(`${AppConfig.ApiUrl}api/members/Create`, {
      memberId: member.id,
      firstname: firstname,
      lastname: lastname,
      email: email,
    })
      .then((r) => {
        member.firstname = r.data.firstname;
        member.lastname = r.data.lastname;
        member.email = r.data.email;

        localStorage.setItem("member", JSON.stringify(member));
        this.setState(
          {
            firstname: member.firstname,
            lastname: member.lastname,
            email: member.email,
            loading: false,
          },
          () => {
            this.props.history.push("/");
          },
        );
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { member, firstname, lastname, email } = this.state;
    return (
      <Layout nothome>
        <section id="create-profile">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>CREATE YOUR PROFILE</h1>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 create-profile-box">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <img
                    src={require("../../assets/images/upload-icon.png")}
                    alt=""
                    className="upload-icon"
                  />

                  <label className="upload-file" htmlFor="uploader">
                    Upload Photo
                  </label>
                  <input
                    type="file"
                    className="upload-file"
                    id="uploader"
                    style={{ display: "none" }}
                    onChange={this.onUploadImage}
                  />
                </div>
              </div>
              <form action="post">
                <input
                  type="text"
                  placeholder="First Name"
                  onChange={(e) => this.setState({ firstname: e.target.value })}
                  value={firstname}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                  value={lastname}
                />
                <input
                  type="email"
                  placeholder="Email"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  value={email}
                />
                <input
                  type="text"
                  placeholder="Mobile Number"
                  value={member.phoneNumber}
                  disabled
                />
              </form>
              <a onClick={this.onEdit}>
                <img
                  src={require("../../assets/images/save-profile.png")}
                  alt=""
                  className="save-profile-button"
                />
              </a>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

const mapState = (state) => {
  return {
    member: state.authReducer.member,
  };
};

export default connect(mapState, null)(CreateProfile);

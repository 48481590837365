export function getMiles(meter) {
  var miles = meter * 0.000621371192;
  return miles.toFixed(2);
}

export function formatMoney(number) {
  return new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    maximumSignificantDigits: 4,
  }).format(number);
}

export function calculateDeposit(number) {
  let deposit = (number * 10) / 100;

  return new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    maximumSignificantDigits: 4,
  }).format(deposit);
}

export function getAmountDue(number) {
  return (number * 10) / 100;
}

export function getCardType(number) {
  var re = new RegExp("^4");

  if (number.match(re) != null) return require("../assets/images/visa.png");

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion

  re = new RegExp("^5[1-5][0-9]");
  if (number.match(re) != null)
    return require("../assets/images/mastercard.png");

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) return require("../assets/images/amex.png");

  // Discover
  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (number.match(re) != null) return require("../assets/images/discover.png");

  // Diners
  re = new RegExp("^36");
  if (number.match(re) != null) return require("../assets/images/diners.png");

  // Diners - Carte Blanche
  re = new RegExp("^30[0-5]");
  if (number.match(re) != null) return require("../assets/images/diners.png");

  // JCB
  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (number.match(re) != null) return require("../assets/images/jcb.png");

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (number.match(re) != null) return require("../assets/images/visa.png");

  return require("../assets/images/unknown.png");
}

export function formatCardNumber(number) {
  let trimmed = number.replace(/\s/g, "");
  let formatted = trimmed.replace(/\d(?=\d{4})/g, "*");
  return formatted;
}

import React from "react";
import Layout from "../../components/common/Layout";

class WireTransfer extends React.Component {
  render() {
    return (
      <Layout nothome>
        <div className="container">
          <div className="row" style={{ marginTop: 50, marginBottom: 50 }}>
            <div className="col-md-6 offset-md-3">
              <h1>YOU'RE ONE STEP AWAY FROM CONFIRMATION</h1>
              <p style={{ color: "grey" }}>
                <strong>
                  Click the call button below to pay by wire with our Saxdor Go
                  team
                </strong>
              </p>
              <p>
                Bookings not paid by wire within 24 hours of request or 4 hours
                prior to deperture -- whichever comes first -- are subject to
                cancellation.
              </p>
              <a
                href="tel:+902164946560"
                className="col-md-6 col-sm-12 call-button"
              >
                <div className="row">
                  <div className="col-md-3" style={{ paddingTop: 5 }}>
                    <img src={require("../../assets/images/call.png")} alt="" />
                  </div>
                  <div
                    className="col-md-9"
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    CALL TO COMPLETE WIRE TRANSFER
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default WireTransfer;

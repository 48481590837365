import Axios from "axios";
import { AppConfig } from "../../config";

export function registerNumber(phone) {
  return (dispatch) => {
    dispatch({
      type: "AUTH_SET_PHONENUMBER",
    });

    Axios.post(`${AppConfig.ApiUrl}api/members/RegisterNumber`, {
      phone,
    })
      .then((r) => {
        dispatch({
          type: "AUTH_SET_PHONENUMBER_OK",
          payload: r.data,
          title: "REGISTIRATION",
          resend: false,
        });
      })
      .catch((e) => {
        if (e.response.status === 404) {
          dispatch({
            type: "AUTH_ALREADYEXISTS",
          });
        } else {
          dispatch({
            type: "AUTH_BADREQUEST",
          });
        }
      });
  };
}

export function validateNumber(phone, isResend) {
  return (dispatch) => {
    dispatch({
      type: "AUTH_SET_PHONENUMBER",
    });

    Axios.post(`${AppConfig.ApiUrl}api/members/ValidateCharter`, {
      phone,
    })
      .then((r) => {
        dispatch({
          type: "AUTH_SET_PHONENUMBER_OK",
          payload: r.data,
          resend: isResend,
        });
      })
      .catch((e) => {
        if (e.response === undefined) {
          dispatch({
            type: "AUTH_BADREQUEST",
          });
          return;
        }
        if (e.response.status === 404) {
          dispatch({
            type: "AUTH_NUMBERNOTFOUND",
          });
        } else {
          dispatch({
            type: "AUTH_BADREQUEST",
          });
        }
      });
  };
}

export function validateCode(phone, code, callback) {
  return (dispatch) => {
    dispatch({
      type: "AUTH_ACTIVATE_MEMBER",
    });

    Axios.post(`${AppConfig.ApiUrl}api/members/ValidateCode`, {
      phone,
      code,
    })
      .then((r) => {
        dispatch({
          type: "AUTH_ACTIVATE_MEMBER_OK",
          payload: r.data,
        });
        if (callback) callback();
      })
      .catch((e) => {
        console.log(e);
        if (e.response === undefined) {
          dispatch({
            type: "AUTH_BADREQUEST",
          });
          return;
        }
        if (e.response.status === 404) {
          dispatch({
            type: "AUTH_CODENOTFOUND",
          });
        } else {
          dispatch({
            type: "AUTH_BADREQUEST",
          });
        }
      });
  };
}

export function clearMessage() {
  return {
    type: "AUTH_CLEARMESSAGE",
  };
}

export function logout() {
  return {
    type: "AUTH_LOGOUT",
  };
}

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AuthActions from "../../redux/actions/auth";
import Layout from "../../components/common/Layout";
import { VDLoader } from "../../components/common/VDLoader";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";

class Register extends React.Component {
  state = {
    message: null,
    loading: false,
    agreed: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.authStore.validationMessage != null) {
      this.setState({ loading: false });
      alert(nextProps.authStore.validationMessage);
      nextProps.clearMessage();
    }

    if (nextProps.authStore.phone != null && !nextProps.authStore.resend) {
      this.props.history.push("/sms-verification");
    }
  }

  componentWillUpdate(prevProps, prevState) {
    if (prevState.message != null) {
      this.setState({ message: null, loading: false });
      alert(prevState.message);
    }
  }

  onSendKey = () => {
    const { phone } = this.state;
    if (isValidPhoneNumber(phone)) {
      this.setState({ loading: true });
      this.props.registerNumber(phone, false);
    } else {
      this.setState({ loading: false });
      this.setState({
        message: "Enter your phone number to login to your Saxdor Go account.",
      });
    }
  };

  render() {
    const { loading } = this.state;
    if (loading) return <VDLoader />;

    return (
      <Layout nothome>
        <section id="login">
          <div className="col-md-5 col-sm-12 login-box">
            <h1>CREATE AN ACCOUNT</h1>
            <p>You're almost ready to Saxdor Go.</p>
            <PhoneInput
              placeholder="Enter phone number"
              value={this.state.phone}
              onChange={(phone) => this.setState({ phone })}
            />
            <p className="grey-text">
              We will send you an SMS of your Activation Key to ensure the phone
              number is valid
            </p>

            <a onClick={this.onSendKey}>
              <img
                src={require("../../assets/images/next.png")}
                alt=""
                className="next-button"
              />
            </a>
            <Link to="/login" className="black-text">
              Already have an Account?
            </Link>
          </div>
        </section>
      </Layout>
    );
  }
}

const mapState = (state) => {
  return {
    authStore: state.authReducer,
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(AuthActions, dispatch);
};

export default connect(mapState, mapDispatch)(Register);

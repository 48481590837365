import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ReservationActions from "../../redux/actions/reservation";
import { scroller } from "react-scroll";

class Footer extends React.Component {
  scrollTo(element_id) {
    this.props.history.push("/");

    scroller.scrollTo(element_id, {
      duration: 600,
      delay: 200,
      smooth: "easeInOutQuart",
    });
  }

  onSelectHotspot = (e, hotspot) => {
    e.preventDefault();
    this.props.setHotspot(hotspot);
    this.props.history.push("/reservation");
  };

  render() {
    const { hotspots } = this.props;

    return (
      <section id="footer">
        <div className="container">
          <div className="row gutter-5">
            <div className="col-md-4 col sm-12">
              <h2 className="footer__header">Contact Us / Book Now</h2>
              <div className="footer__booking-option">
                <a
                  className="footer__booking-option-link"
                  href="tel:+905308857446"
                  target="_blank"
                >
                  <img
                    src={require("../../assets/images/phone-icon.png")}
                    alt="book by phone"
                  />
                  <p className="footer__booking-option-text">
                    +90 530 885 74 46
                  </p>
                </a>
              </div>
              <div className="footer__booking-option">
                <a
                  className="footer__booking-option-link"
                  href="sms:+905308857446"
                  target="_blank"
                >
                  <img
                    src={require("../../assets/images/sms-icon.png")}
                    alt="Book Via Text"
                  />
                  <p className="footer__booking-option-text">Book Via Text</p>
                </a>
              </div>
              <div className="footer__booking-option">
                <a
                  className="footer__booking-option-link"
                  href="mailto:sales@saxdorturkey.com"
                  target="_blank"
                >
                  <img
                    src={require("../../assets/images/email-icon.png")}
                    alt="book by mail"
                  />
                  <p className="footer__booking-option-text">
                    sales@saxdorturkey.com
                  </p>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <h2 className="footer__header">Search By Location</h2>
              <ul className="footer__locations">
                {hotspots &&
                  hotspots.items != null &&
                  hotspots.items.map((hotspot, i) => (
                    <li className="footer__location-item" key={i}>
                      <Link
                        to="/"
                        className="footer__location-link"
                        onClick={(e) => this.onSelectHotspot(e, hotspot)}
                      >
                        {hotspot.Name}
                      </Link>
                    </li>
                  ))}
              </ul>
              <div className="footer__social-media">
                <h2 className="footer__header">Social Media</h2>
                <ul className="footer__social-media-items">
                  <li className="footer__social-media-item">
                    <a
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/saxdorturkey_theonemarine/"
                      target="_blank"
                      className="footer__social-media-link"
                    >
                      <img
                        src={require("../../assets/images/Instagram_Icon.png")}
                        alt=""
                      />
                      <span className="footer__social-media-text">
                        Follow Us
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col sm-12">
              <div className="footer__newsletter-wrapper">
                <img src={require("../../assets/logo.svg")} alt="saxdor" />
                <div className="footer__newsletter-description">
                  <h2 className="footer__header">JOIN NEWSLETTER</h2>
                  <p>
                    Register with Saxdor Go to receive lifestyle content, news,
                    product information and more.
                  </p>
                </div>
              </div>
              <div className="footer__newsletter-input-container">
                <label
                  htmlFor="email"
                  className="footer__newsletter-input-label"
                >
                  <input
                    className="footer__newsletter-input"
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Email Address"
                  />
                </label>
                <div className="footer__newsletter-submit-button">
                  <button>
                    <div className="footer__newsletter-submit-button-text">
                      Sign Up
                    </div>
                  </button>
                </div>
              </div>
              <div className="footer__download-app">
                <h2 className="footer__header">Download Saxdor Go</h2>
                <p className="footer__download-app-description">
                  Available now on the Apple App Store.
                </p>
                <a rel="noopener noreferrer" href="#" target="_blank">
                  <img
                    src={require("../../assets/images/app-store.png")}
                    alt=""
                    style={{ width: 200 }}
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <p className="copyright">
                2021 TM & ©2021 Saxdor Go.{" "}
                <a
                  rel="noopener noreferrer"
                  href="http://saxdorturkey.com/terms-of-service.html"
                  target="_blank"
                >
                  Legal notice
                </a>
                ,{" "}
                <a
                  rel="noopener noreferrer"
                  href="http://saxdorturkey.com/privacy-policy.html"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hotspots: state.appReducer.hotspots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...ReservationActions }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));

import React from "react";
import Layout from "../../components/common/Layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ReservationActions from "../../redux/actions/reservation";
import { Redirect } from "react-router-dom";
import { AppConfig } from "../../config";
import moment from "moment";
import GoogleMapReact from "google-map-react";

import { formatMoney } from "../../helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

class AcceptAndPay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      member: null,
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    const member = JSON.parse(localStorage.getItem("member"));
    this.setState({ authenticated: token != null, member });
  }

  get timePeriod() {
    const { reservation } = this.props;
    return reservation.Detail.IsFullDay ? "FULL DAY" : "HALF DAY";
  }

  get boatImages() {
    const { reservation } = this.props;

    return reservation.ActiveBoat.Images
      ? reservation.ActiveBoat.Images.map((image, i) => (
          <div className="col-md-4" key={i}>
            <img src={AppConfig.ApiUrl + image.Image} alt="" className="img" />
          </div>
        ))
      : "";
  }

  onAcceptAndPay = () => {
    this.props.history.push("/payment");
  };

  render() {
    const { reservation } = this.props;

    let charterFee = reservation.Detail.Price;
    let convenienceFee = (charterFee * 3) / 100;
    let tax = (charterFee * reservation.Detail.TaxRate) / 100;
    let total = charterFee + convenienceFee;
    return (
      <Layout nothome>
        <section id="accept-pay">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="back-button">
                  <Link to="/selected-boat-detail">
                    <FontAwesomeIcon icon={faAngleLeft} /> Back
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <span className="red-text">RESERVATION REVIEW</span>
                <h1 className="choosed-boat">{`${reservation.ActiveBoat.Year} ${reservation.ActiveBoat.Make} ${reservation.ActiveBoat.Model}`}</h1>
                <div className="day-price">
                  {this.timePeriod === "FULL DAY" ? (
                    <p>{formatMoney(reservation.ActiveBoat.Price.FullDayPrice)} FULL DAY</p>
                  ) : (
                    <p>{formatMoney(reservation.ActiveBoat.Price.HalfDayPrice)} HALF DAY</p>
                  )}
                </div>

                <div className="row summary">
                  <div className="col-md-4 col-sm-12">
                    <span className="red-text">PICK UP</span>
                    <p className="">
                      {moment(reservation.Detail.PickupTime.Date).format(
                        "MMM. D"
                      )}
                      <br />
                      {moment(
                        reservation.Detail.PickupTime.Date +
                          "T" +
                          reservation.Detail.PickupTime.Hour
                      ).format("LT")}
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <span className="red-text">DROP OFF</span>
                    <p className="">
                      {moment(reservation.Detail.ReturnTime.Date).format(
                        "MMM. D"
                      )}
                      <br />
                      {moment(
                        reservation.Detail.ReturnTime.Date +
                          "T" +
                          reservation.Detail.ReturnTime.Hour
                      ).format("LT")}
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <span className="red-text"># GUESTS</span>
                    <p>{reservation.GuestAmount}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12  selected-boat-image-gallery">
                <img
                  src={`${
                    AppConfig.AssetUrl + reservation.ActiveBoat.CoverImage
                  }`}
                  alt=""
                />
                {/* <div className="row selected-boat-image-gallery">
                    <br />
                    {this.boatImages}
                  </div> */}
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="location-preview">
                  <p>
                    <span className="red-text">PICK UP</span>
                    LOCATION
                  </p>
                  <GoogleMapReact
                    defaultCenter={{
                      lat: this.props.reservation.Detail.PickupLocation
                        .Latitude,
                      lng: this.props.reservation.Detail.PickupLocation
                        .Longitude,
                    }}
                    center={{
                      lat: this.props.reservation.Detail.PickupLocation
                        .Latitude,
                      lng: this.props.reservation.Detail.PickupLocation
                        .Longitude,
                    }}
                    defaultZoom={10}
                  >
                    <MapMarker
                      lat={
                        this.props.reservation.Detail.PickupLocation.Latitude
                      }
                      lng={
                        this.props.reservation.Detail.PickupLocation.Longitude
                      }
                      text={
                        <img
                          src={require("../../assets/images/marker.png")}
                          style={{ maxWidth: 100, width: 30 }}
                          alt=""
                        />
                      }
                    />
                  </GoogleMapReact>
                  {reservation.ActiveBoat.Location.Marina && (
                    <p>{reservation.ActiveBoat.Location.Marina.Name}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="location-preview drop-off-preview">
                  <p>
                    <span className="red-text">DROP OFF</span>
                    LOCATION
                  </p>
                  <GoogleMapReact
                    defaultCenter={{
                      lat: this.props.reservation.Detail.DropOffLocation
                        .Latitude,
                      lng: this.props.reservation.Detail.DropOffLocation
                        .Longitude,
                    }}
                    center={{
                      lat: this.props.reservation.Detail.DropOffLocation
                        .Latitude,
                      lng: this.props.reservation.Detail.DropOffLocation
                        .Longitude,
                    }}
                    defaultZoom={10}
                  >
                    <MapMarker
                      lat={
                        this.props.reservation.Detail.DropOffLocation.Latitude
                      }
                      lng={
                        this.props.reservation.Detail.DropOffLocation.Longitude
                      }
                      text={
                        <img
                          src={require("../../assets/images/marker.png")}
                          style={{ maxWidth: 100, width: 30 }}
                          alt=""
                        />
                      }
                    />
                  </GoogleMapReact>
                  {reservation.ActiveBoat.Location.Marina && (
                    <p>{reservation.ActiveBoat.Location.Marina.Name}</p>
                  )}
                </div>
              </div>
            </div>
            <hr style={{ marginTop: 80 }} />
            <div className="prices-fees">
              <div className="row">
                <div className="col-md-12" style={{ marginBottom: 20 }}>
                  <span className="red-text">FARES & DETAILS</span>
                </div>
              </div>
              <div className="row">
                <div className="col-6 fee-item">RESERVATION RATE</div>
                <div className="col-6 fee-item">{formatMoney(charterFee)}</div>
              </div>
              <div className="row">
                <div className="col-6 fee-item">CONVIENCE FEE</div>
                <div className="col-6 fee-item">
                  {formatMoney(convenienceFee)}
                </div>
              </div>
              <div className="row">
                <div className="col-6 fee-item">TAX</div>
                <div className="col-6 fee-item">{formatMoney(tax)}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-6 total-fee">TOTAL</div>
                <div className="col-6 total-fee">{formatMoney(total)}</div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <img
                  src={require("../../assets/images/accept-pay.png")}
                  alt=""
                  className="img accept-pay-button"
                  onClick={this.onAcceptAndPay}
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const MapMarker = ({ text }) => (
  <div
    style={{
      transform: "translate(-50%, -50%)",
    }}
  >
    {text}
  </div>
);

const mapStateToProps = (state) => {
  return {
    reservation: state.reservationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ReservationActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptAndPay);

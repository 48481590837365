import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Index from "./pages/index";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "./responsive.css";

ReactDOM.render(
  <Provider store={store}>
    <Index />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();

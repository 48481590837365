import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as ReservationActions from "../../redux/actions/reservation";
import * as AppActions from "../../redux/actions/app";
import SingleBoat from "./SingleBoat";

class AllBoats extends React.Component {
  onSelectBoat = (boat) => {
    const { reservation } = this.props;
    let price = reservation.Detail.IsFullDay
      ? boat.Price.FullDayPrice
      : boat.Price.HalfDayPrice;

    this.props.setPrice(price, boat.Price.TaxRate);
    this.props.setBoat(boat);

    this.props.history.push("/selected-boat-detail");
  };

  get boats() {
    const { boats, reservation } = this.props;

    if (boats.items.length === 0)
      return <p>No available yachts found at this location.</p>;

    return boats.items.map((boat, i) => {
      return (
        <SingleBoat
          key={i}
          boat={boat}
          reservation={reservation}
          onClick={() => this.onSelectBoat(boat)}
        />
      );
    });
  }

  render() {
    return (
      <section id="all-boats">
        <div className="container">
          <div className="row">{this.boats}</div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    boats: state.appReducer.boats,
    location: state.appReducer.location,
    reservation: state.reservationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...ReservationActions, ...AppActions }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllBoats),
);

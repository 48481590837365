import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AppActions from "../redux/actions/app";
import Layout from "../components/common/Layout";
import { VDLoader } from "../components/common/VDLoader";
import { Landing } from "../components/Home/Landing";
import { HowItWorks } from "../components/Home/HowItWorks";
import SelectHotspot from "../components/Home/SelectHotspot";

class Home extends React.Component {
  componentDidMount() {
    this.props.getMember();
    this.props.getHotspots();

    const header = document.querySelector(".header");
    if (header) header.classList.toggle("at-home");

    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 10 ||
        document.documentElement.scrollTop > 10
      ) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    });
  }

  componentWillUnmount() {
    const header = document.querySelector(".header");
    if (header) {
      header.classList.toggle("at-home");
      header.classList.remove("scrolled");
    }
    window.removeEventListener("scroll", () => {
      if (
        document.body.scrollTop > 10 ||
        document.documentElement.scrollTop > 10
      ) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    });
  }

  render() {
    const { hotspots } = this.props;
    if (hotspots.loading) return <VDLoader />;

    return (
      <Layout>
        <Landing />
        <SelectHotspot hotspots={hotspots.items} />
        <HowItWorks />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    member: state.appReducer.member,
    reservation: state.reservationReducer,
    hotspots: state.appReducer.hotspots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...AppActions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

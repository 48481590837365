import axios from 'axios';
import { AppConfig } from '../../config/index';

export function storeMember(member) {
  return (dispatch) => {
    dispatch({
      type: 'GET_MEMBER',
      payload: member
    });
  };
}

export function getMember() {
  return (dispatch) => {
    const member = localStorage.getItem('member');
    console.log(member);
    if (member === undefined || member === null) {
      dispatch({
        type: 'GET_MEMBER',
        payload: undefined
      });
    } else {
      const authMember = JSON.parse(member);
      if (member != null) {
        axios
          .get(`${AppConfig.ApiUrl}api/members/${authMember.id}`)
          .then((r) => {
            localStorage.setItem('member', JSON.stringify(r.data));
            dispatch({
              type: 'GET_MEMBER',
              payload: r.data
            });
          })
          .catch((e) => {
            console.log(e);
            dispatch({
              type: 'GET_MEMBER',
              payload: authMember
            });
          });
      }
    }
  };
}

export function setLocation(lat, long) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOCATION',
      payload: { lat, long }
    });
  };
}

export function getHotspots() {
  return (dispatch) => {
    axios
      .get(`${AppConfig.ApiUrl}odata/hotspots?$expand=Boats&$filter=IsDeleted eq false`)
      .then((r) => {
        dispatch({
          type: 'GET_HOTSPOTS',
          payload: r.data.value
        });
      })
      .catch((e) => {
        dispatch({
          type: 'GET_HOTSPOTS_FAIL'
        });
      });
  };
}

export function getHotspotsByLocation(lat, long) {
  return (dispatch) => {
    axios
      .get(
        `${AppConfig.ApiUrl}odata/hotspots/getbylocation(latitude=${lat},longitude=${long})?$filter=IsDeleted eq false`
      )
      .then((r) => {
        dispatch({
          type: 'GET_HOTSPOTS_BY_LOCATION',
          payload: r.data.value
        });
      })
      .catch((e) => {
        dispatch({
          type: 'GET_HOTSPOTS_FAIL'
        });
      });
  };
}

export function getBoats(id, pickupTime, returnTime) {
  return (dispatch) => {
    dispatch({
      type: 'GET_BOATS'
    });

    axios
      .post(
        `${AppConfig.ApiUrl}odata/hotspots(${id})/charterboats?$expand=Detail,Specs,Location($expand=Marina),Images,Price`,
        {
          PickupTime: pickupTime,
          ReturnTime: returnTime
        }
      )
      .then((r) => {
        dispatch({
          type: 'GET_BOATS_OK',
          payload: r.data.value
        });
      });
  };
}
